import { pipe } from 'fp-ts/lib/function';
import type { Option } from 'fp-ts/lib/Option';
import { chain, fromNullable } from 'fp-ts/lib/Option';
import { intersection, xor } from 'lodash';
import {
  PlaylistIdSchema,
  ResourceIdSchema,
  WorkspaceIdSchema,
} from '../../common/Opaques';
import type { Song } from '../../common/Song';
import type { Playlist } from '../types/Playlist';
import { randomIn } from './math-utils';
import { getSongById } from './song-utils';
import { filterBySubStringAndSort } from './string-utils';

/**
 * @deprecated
 */
export default function checkPlaylistIfOwned(
  _playlist: Playlist,
  _loggedUserId: string
) {
  // return !!playlist.collaborators.find(
  //   (collaborator) =>
  //     collaborator.role === 'playlist_owner' &&
  //     collaborator.user.userId === loggedUserId
  // );
  return true;
}

export function checkPlaylistIfHasArtwork(
  playlist?: Playlist,
  newArtworkUrl?: string
): boolean {
  return Boolean(
    (playlist?.coverImagePath && newArtworkUrl !== '') || newArtworkUrl
  );
}

export function filterVisiblePlaylists(
  location: any,
  allPlaylists: Playlist[]
) {
  const { pathname } = location;
  const parts = pathname.split('/');
  if (parts[1] === 'filter' && parts[2] === 'PLAYLISTS') {
    return filterBySubStringAndSort(allPlaylists, parts[3], ['title']).map(
      (playlist) => playlist.playlistId
    );
  }

  return [];
}

export function playlistFromLocation(location: any, allPlaylists: Playlist[]) {
  const { pathname } = location;
  const parts = pathname.split('/');
  if (parts[1] === 'playlist' && parts[2]) {
    const playlistID = parts[2];
    return allPlaylists.find((pl) => pl.playlistId === playlistID);
  }

  return undefined;
}

export function findPlaylistById(
  allPlaylists: Playlist[],
  playlistId: string
): Playlist | undefined {
  return allPlaylists.find((pl: Playlist) => pl.playlistId === playlistId);
}

export function atLeastOneSongWasAdded(
  oldPlaylist: Playlist,
  newPlaylist: Playlist,
  addedSongs: string[]
): boolean {
  return pipe(
    xor(oldPlaylist.songIds, newPlaylist.songIds),
    (diff) => intersection(diff, addedSongs).length > 0
  );
}

export function getPlaylistById(playlists: Playlist[]) {
  return (playlistId: string): Option<Playlist> => {
    return fromNullable(findPlaylistById(playlists, playlistId));
  };
}

export function getFirstSongInPlaylist(songs: Song[]) {
  return (playlist: Playlist): Option<Song> => {
    return pipe(playlist?.songIds[0], fromNullable, chain(getSongById(songs)));
  };
}

export function getRandomSongInPlaylist(songs: Song[]) {
  return (playlist: Playlist): Option<Song> => {
    return pipe(
      playlist,
      fromNullable,
      chain((p) => pipe(p.songIds[randomIn(p.songIds.length)], fromNullable)),
      chain(getSongById(songs))
    );
  };
}

export const emptyPlaylist: Playlist = {
  coverImageOrigPath: null,
  coverImagePath: null,
  createdAt: new Date('2023-11-14T19:31:41.961Z'),
  deletedAt: null,
  description: 'Playlist description',
  docType: 'playlist',
  folderId: null,
  isUnread: false,
  permissionList: [],
  playlistId: PlaylistIdSchema.parse('1'),
  playlistReadAt: null,
  projectId: null,
  publicLinkUrl: null,
  resourceId: ResourceIdSchema.parse('rpl1'),
  role: null,
  shareId: null,
  songIds: [],
  title: 'Playlist title',
  workspaceId: WorkspaceIdSchema.parse(''),
};
