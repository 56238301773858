import { z } from 'zod';
import { DeviceIdSchema } from '../../common/Opaques';
import { transformToDate, transformToNull } from '../../common/zod-utils';

export const PerkSchema = z
  .object({
    perk_id: z.string(),
    completed_at: z
      .string()
      .transform(transformToDate)
      .nullish()
      .transform(transformToNull),
    type: z.string(),
    name: z.string(),
    val: z.string(),
  })
  .transform((prev) => ({
    perkId: prev.perk_id,
    completedAt: prev.completed_at,
    type: prev.type,
    name: prev.name,
    val: prev.val,
  }));

export type Perk = z.infer<typeof PerkSchema>;

export const SegmentSchema = z
  .object({
    duration_remaining_mts: z.number(),
    duration_size: z.number(),
    duration_size_hrs: z.number(),
    segment_type: z.string(),
  })
  .transform((prev) => ({
    durationMts: prev.duration_remaining_mts,
    durationSize: prev.duration_size,
    durationSizeHrs: prev.duration_size_hrs,
    segmentType: prev.segment_type,
  }));

export const StorageInfoSchema = z
  .object({
    is_full: z.boolean(),
    is_storage_full: z.boolean(),
    segments: z.array(SegmentSchema),
    shared_playlist_limit: z.number(),
    shared_playlist_count: z.number(),
    shared_playlists_remaining: z.number(),
  })
  .transform((prev) => ({
    isFull: prev.is_full,
    isStorageFull: prev.is_storage_full,
    segments: prev.segments,
    sharedPlaylistLimit: prev.shared_playlist_limit,
    sharedPlaylistCount: prev.shared_playlist_count,
    sharedPlaylistsRemaining: prev.shared_playlists_remaining,
  }));

export type StorageInfo = z.infer<typeof StorageInfoSchema>;

export const DeviceSchema = z.object({
  created_at: z.string().transform(transformToDate),
  device_identifier: DeviceIdSchema,
  device_name: z.string().nullish(),
  device_platform: z.string().nullish(),
  device_type: z.string().nullish(),
  id: z.undefined().transform(() => window.crypto.randomUUID()), // there is no unique id for devices, this is a workarround to provide one
  location: z.string(),
});

export type Device = z.infer<typeof DeviceSchema>;

export const OnboardingStepSchema = z.object({
  created_at: z.string(),
  order_id: z.number(),
  step_id: z.enum([
    'complete_profile',
    'import_track',
    'create_playlist',
    'share_playlist',
    'invite_team_member',
    'download_mobile_app',
  ]),
  subtitle: z.string().nullish(),
  title: z.string(),
});

export type OnboardingStep = z.infer<typeof OnboardingStepSchema>;

export const WebURLSchema = z.object({
  account_manage: z.string(),
  create_workspace_url: z.string(),
  download: z.string(),
  help_page: z.string(),
  landing_page: z.string(),
  // plan_business_checkout: z.string(),
  // plan_professional_checkout: z.string(),
  // plan_starter_checkout: z.string(),
  privacy: z.string(),
  signup: z.string(),
  support_email: z.string(),
  terms: z.string(),
  upgrade: z.string(),
  web_host: z.string(),
  workspace_manage: z.string(),
});

export const OnboardingStepsSchema = z
  .array(OnboardingStepSchema.catch(() => null!))
  .transform((x) =>
    x
      .filter((_): _ is NonNullable<typeof _> => _ !== null)
      .sort((a, b) => a.order_id - b.order_id)
  );

export type WebURL = z.infer<typeof WebURLSchema>;

export const UserInfoSchema = z.object({
  web_urls: WebURLSchema,
  available_onboarding_steps: OnboardingStepsSchema,
  completed_onboarding_steps: OnboardingStepsSchema,
  connected_devices: z.array(DeviceSchema),
  // steps_complete: z.boolean(),
  perk_list: z.array(PerkSchema),
  storage_info: StorageInfoSchema.nullish(),
});

export type UserInfo = z.infer<typeof UserInfoSchema>;
