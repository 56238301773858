import clsx from 'clsx';
import { constant } from 'fp-ts/lib/function';
import { AnimatePresence, motion } from 'motion/react';
import { useEffect, useState } from 'react';
import { match, P } from 'ts-pattern';
import type { PlayerTemplateProps } from '../renderer/components/player/PlayerTemplate';
import { Spinner } from '../renderer/theme/data-display/Icons/Spinner/Spinner';
import { api } from './api';
import { ErrorPage } from './ErrorPage';
import styles from './Home.module.scss';
import { Password } from './Password';
import { Player } from './Player';
import { SomethingWentWrong } from './SomethingWentWrong';
import { TopBar } from './TopBar';
import { AudioList } from './TrackList';

interface HomeProps {
  shareId: string;
}

export function Home({ shareId }: HomeProps) {
  // https://trn-ctrl-api-staging-w2.herokuapp.com/public/share/6VJj318g0
  const [password, setPassword] = useState('');
  const { data, error, isFetching } = api.useGetSharedLinkQuery([
    shareId,
    password,
  ]);

  const [layout, setLayout] =
    useState<PlayerTemplateProps['layout']>('compact');

  useEffect(() => {
    const mq = window.matchMedia('(max-width: 800px)');
    setLayout(mq.matches ? 'compact' : 'mid');

    function handleMediaChange(e: MediaQueryListEvent) {
      setLayout(e.matches ? 'compact' : 'mid');
    }

    const unsubscribe = mq.addEventListener('change', handleMediaChange);

    return unsubscribe;
  }, []);

  return (
    <>
      <TopBar />
      <AnimatePresence mode="wait">
        {match({ data, error, isFetching, shareId })
          .with(
            { isFetching: true },
            constant(
              <motion.div
                key="spinner"
                animate={{ opacity: 1 }}
                className={clsx(
                  styles.body,
                  layout === 'compact' && styles.compact
                )}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Spinner />
              </motion.div>
            )
          )
          .with(
            { error: { data: { error: 'PASSWORD_REQUIRED' } } },
            constant(
              <motion.div
                key="password"
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Password onPasswordSubmit={setPassword} />
              </motion.div>
            )
          )
          .with(
            { error: { originalStatus: P.number } },
            ({ error: { originalStatus } }) => (
              <motion.div
                key="status error"
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <ErrorPage status={originalStatus} />
              </motion.div>
            )
          )
          .with(
            { shareId: P.not(P.string.minLength(1)) },
            constant(
              <motion.div
                key="no share id"
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <ErrorPage status={404} />
              </motion.div>
            )
          )
          .with({ data: P.nonNullable }, (r) => (
            <motion.div
              key="data"
              animate={{ opacity: 1 }}
              className={clsx(
                styles.body,
                layout === 'compact' && styles.compact
              )}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <AudioList sharedLink={r.data} shareId={shareId} />
              <Player layout={layout} sharedLink={r.data} shareId={shareId} />
            </motion.div>
          ))
          .otherwise(
            constant(
              <motion.div
                key="otherwise"
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <SomethingWentWrong />
              </motion.div>
            )
          )}
      </AnimatePresence>
    </>
  );
}
