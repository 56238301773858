import { head, uniqBy } from 'lodash';
import type { Project } from '../services/api/types/Workspace';

export function sortByCreatedAtComparer(a: Project, b: Project): number {
  return a.createdAt > b.createdAt ? 1 : -1;
}

export function sortByCreatedAt<T extends Project>(projects: T[]): T[] {
  return uniqBy(
    // TODO: remove, this is to prevent a backend bug with duplicated lists and no titles
    projects.filter((p) => p.title.length),
    (p) => p.stringId
  ).sort(sortByCreatedAtComparer);
}

export function pickFirst(projects: Project[]): Project | undefined {
  return head(sortByCreatedAt(projects));
}
