import type { PayloadAction } from '@reduxjs/toolkit';
import {
  addListener,
  configureStore,
  createAsyncThunk,
  createListenerMiddleware,
  createSlice,
} from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { createLogger } from 'redux-logger';
import VOLUME_TICK from '../renderer/constants/player';
import { API_HOST } from '../renderer/services/apiHost';
import { euclideanModulo } from '../renderer/utils/math-utils';
import type { Audio, SharedLink } from './types';
import { SharedLinkSchema } from './types';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: API_HOST }),
  endpoints: (builder) => ({
    getSharedLink: builder.query<SharedLink, [string, string]>({
      query: ([id, password]) => ({
        url: `public/share/${id}`,
        method: 'POST',
        body: { password },
        validateStatus(response, body) {
          return response.status === 200 && !('error' in body);
        },
      }),
      transformResponse: (response) => SharedLinkSchema.parse(response),
    }),
  }),
});

type PlayerState = {
  selectedAudio?: Audio;
  isPlaying: boolean;
  volume: number;
  muted: boolean;
};

const playerInitialState: PlayerState = {
  isPlaying: false,
  volume: 0.5,
  muted: false,
};

export const playerSlice = createSlice({
  name: 'player',
  initialState: playerInitialState,
  reducers: {
    togglePlayback(state) {
      if (state.selectedAudio) {
        state.isPlaying = !state.isPlaying;
      }
    },
    mount(state, action: PayloadAction<Audio>) {
      state.selectedAudio = action.payload;
      state.isPlaying = true;
    },
    finishedPlaylist(state, action: PayloadAction<Audio>) {
      state.selectedAudio = action.payload;
      state.isPlaying = false;
    },
    toggleMute(state) {
      state.muted = !state.muted;
    },
    setVolume(state, action: PayloadAction<number>) {
      if (state.muted) {
        state.muted = false;
      }
      state.volume = action.payload;
    },
    mute(state) {
      state.muted = true;
    },
    volumeDown(state) {
      if (state.muted) {
        state.muted = false;
      }
      state.volume = Math.max(0, state.volume - VOLUME_TICK);
    },
    volumeUp(state) {
      if (state.muted) {
        state.muted = false;
      }
      state.volume = Math.min(1, state.volume + VOLUME_TICK);
    },
    volumeMax(state) {
      if (state.muted) {
        state.muted = false;
      }
      state.volume = 1;
    },
  },
});

const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [playerSlice.name]: playerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
      .concat(api.middleware)
      .concat(
        process.env.NODE_ENV === 'production'
          ? []
          : [
              createLogger({
                level: 'info',
                collapsed: true,
              }),
            ]
      ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const addAppListener = addListener.withTypes<RootState, AppDispatch>();

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();

export const selectAudio = createAppAsyncThunk(
  'player/selectAudio',
  async (audio: Audio, { getState, dispatch }) => {
    const state = getState();
    if (state.player.selectedAudio?.audio_id !== audio.audio_id) {
      dispatch(playerSlice.actions.mount(audio));
    } else {
      dispatch(playerSlice.actions.togglePlayback());
    }
  }
);

export const togglePlayback = createAppAsyncThunk(
  'player/togglePlayback',
  async ([shareId, password]: [string, string], { getState, dispatch }) => {
    const state = getState();
    if (!state.player.selectedAudio) {
      const shareLink = api.endpoints.getSharedLink.select([shareId, password])(
        state
      ).data;

      const head = shareLink?.audio_list[0];
      if (head) {
        dispatch(selectAudio(head));
      }
    } else {
      dispatch(playerSlice.actions.togglePlayback());
    }
  }
);

export const playNext = createAppAsyncThunk(
  'player/next',
  async ([shareId, password]: [string, string], { getState, dispatch }) => {
    const state = getState();
    const shareLink = api.endpoints.getSharedLink.select([shareId, password])(
      state
    ).data;

    if (shareLink) {
      const index = shareLink.audio_list.findIndex(
        (_) => _.audio_id === state.player.selectedAudio?.audio_id
      );
      const nextIndex = euclideanModulo(index + 1, shareLink.audio_list.length);
      const nextAudio = shareLink.audio_list[nextIndex];
      if (nextAudio) {
        dispatch(playerSlice.actions.mount(nextAudio));
      }
    }
  }
);

export const songEnded = createAppAsyncThunk(
  'player/song ended',
  async ([shareId, password]: [string, string], { getState, dispatch }) => {
    const state = getState();
    const shareLink = api.endpoints.getSharedLink.select([shareId, password])(
      state
    ).data;

    if (shareLink) {
      const index = shareLink.audio_list.findIndex(
        (_) => _.audio_id === state.player.selectedAudio?.audio_id
      );
      const nextIndex = euclideanModulo(index + 1, shareLink.audio_list.length);
      const nextAudio = shareLink.audio_list[nextIndex];
      if (nextAudio) {
        if (nextIndex === 0) {
          dispatch(playerSlice.actions.finishedPlaylist(nextAudio));
        } else {
          dispatch(playerSlice.actions.mount(nextAudio));
        }
      }
    }
  }
);

export const playPrevious = createAppAsyncThunk(
  'player/next',
  async ([shareId, password]: [string, string], { getState, dispatch }) => {
    const state = getState();
    const shareLink = api.endpoints.getSharedLink.select([shareId, password])(
      state
    ).data;

    if (shareLink) {
      const index = shareLink.audio_list.findIndex(
        (_) => _.audio_id === state.player.selectedAudio?.audio_id
      );
      const nextIndex = euclideanModulo(index - 1, shareLink.audio_list.length);
      const nextAudio = shareLink.audio_list[nextIndex];
      if (nextAudio) {
        dispatch(playerSlice.actions.mount(nextAudio));
      }
    }
  }
);
