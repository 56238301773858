/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import type { ComponentProps } from 'react';
import { forwardRef } from 'react';
import styled from './MaskIcon.module.scss';

export const allVariants = [
  'activity',
  'arrowSeparateVertical',
  'attachment',
  'burgerMenu',
  'calendar',
  'check',
  'chevronDownS',
  'chevronDownXS',
  'chevronL',
  'clock',
  'close',
  'close2',
  'closedLock',
  'closedLock2',
  'comments',
  'compose',
  'dots',
  'download',
  'downloadOutlined',
  'expandPlayer',
  'eyeClosed',
  'eyeOpen',
  'fileInfo',
  'folderClosed',
  'folderOpened',
  'language',
  'link',
  'next',
  'notes',
  'openLock',
  'padlock',
  'pause',
  'pauseInline',
  'pinned',
  'play',
  'playInline',
  'playingInline',
  'previous',
  'privatePlaylist',
  'privateProject',
  'project',
  'queue',
  'queueDownload',
  'repeat',
  'repeatOnce',
  'search',
  'shield',
  'shrinkPlayer',
  'shuffle',
  'sparkle',
  'starMixed',
  'starOutline',
  'starStarred',
  'unpin',
  'volumeHigh',
  'volumeLow',
  'volumeMuted',
] as const;

/**
 * omit onClick to match css no pointer events, pointer events are removed to avoid clicking the icon when dragging elements
 */
export type MaskIconProps = Omit<ComponentProps<'div'>, 'onClick'> & {
  variant: (typeof allVariants)[number];
  iconRotationDegrees?: number;
  rotation?: 180;
  size?: number;
};

export const MaskIcon = forwardRef<HTMLDivElement, MaskIconProps>(
  (
    {
      className,
      iconRotationDegrees = 0,
      style,
      variant,
      rotation,
      size = 16,
      ...props
    },
    ref
  ) => (
    <div
      ref={ref}
      className={clsx(
        'MaskIcon',
        styled.root,
        styled[variant],
        rotation && styled[`rotation-${rotation}`],
        'bg-icon-default',
        className
      )}
      style={{
        ...style,
        rotate: `${iconRotationDegrees}deg`,
        height: size,
        width: size,
        minWidth: size,
        minHeight: size,
        maskSize: `${size}px ${size}px`,
        WebkitMaskSize: `${size}px ${size}px`,
      }}
      {...props}
    />
  )
);
