import { z } from 'zod';
import type { UserId } from '../../../../common/Opaques';
import {
  ProjectIdSchema,
  ProjectInvitationIdSchema,
  UserIdSchema,
} from '../../../../common/Opaques';
import { fromNullish } from '../../../../common/zod-utils';

export const ProjectMemberRoleSchema = z.union([
  z.literal('project_guest'),
  z.literal('project_editor'),
  z.literal('project_viewer'),
]);

export const ProjectMemberUserSchema = z
  .object({
    artist_name: z.string().nullable().transform(fromNullish('')),
    artwork_url: z.string().nullable(),
    avatar_url: z.string().nullable(),
    bio: z.string().nullable(),
    created_at: z.string().transform((date) => new Date(date)),
    deleted_at: z.string().nullable(),
    email: z.string(),
    location: z.string().nullable(),
    // member_since: z.string().transform((date) => new Date(date)),
    name: z.string().nullable().transform(fromNullish('')),
    occupation: z.string().nullable(),
    // project_id: z.string(),
    // project_role: z.string(),
    steps_complete: z.number().nullable(),
    user_id: UserIdSchema,
    website: z.string().nullable(),
  })
  .transform((prev) => ({
    artistName: prev.artist_name,
    artworkUrl: prev.artwork_url,
    avatarUrl: prev.avatar_url, // TODO: this images are not size appropriate, they are 1024x1024 instead of 30x30
    bio: prev.bio,
    createdAt: prev.created_at,
    deletedAt: prev.deleted_at,
    email: prev.email,
    location: prev.location,
    // memberSince: prev.member_since,
    name: prev.name,
    occupation: prev.occupation,
    // projectId: prev.project_id,
    // projectRole: prev.project_role,
    stepsComplete: prev.steps_complete,
    userId: prev.user_id,
    website: prev.website,
  }));

export const ProjectMemberSchema = z
  .object({
    // project_role: ProjectMemberRoleSchema,
    project_id: z.string(),
    user: ProjectMemberUserSchema,
  })
  .transform((prev) => ({
    // projectRole: prev.project_role,
    projectId: prev.project_id,
    user: prev.user,
  }));

export type ProjectMember = z.infer<typeof ProjectMemberSchema>;

export const ProjectInvitationSchema = z
  .object({
    created_at: z.string().transform((date) => new Date(date)),
    email: z.string(),
    role: ProjectMemberRoleSchema,
    invitation_id: ProjectInvitationIdSchema,
    project_id: ProjectIdSchema,
  })
  .transform((prev) => ({
    createdAt: prev.created_at,
    email: prev.email,
    role: prev.role,
    invitationId: prev.invitation_id,
    projectId: prev.project_id,
  }));

export type ProjectInvitation = z.infer<typeof ProjectInvitationSchema>;

export const defaultProjectMember: ProjectMember = {
  // projectRole: 'project_editor',
  user: {
    artistName: 'artistName',
    artworkUrl: 'https://i.pravatar.cc/150?img=1',
    avatarUrl: 'https://i.pravatar.cc/150?img=1',
    createdAt: new Date('2024-01-13T14:11:40.000Z'),
    email: 'email',
    location: 'location',
    name: 'name',
    occupation: 'occupation',
    userId: 'userId' as UserId,
    // memberSince: new Date('2024-01-13T14:11:40.000Z'),
    // projectId: ProjectIdSchema.parse('s_vxwaEOn'),
    // projectRole: 'project_editor',
    stepsComplete: 1,
    website: null,
    bio: null,
    deletedAt: null,
  },
  projectId: ProjectIdSchema.parse('s_vxwaEOn'),
};
