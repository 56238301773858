export const BACK_HISTORY_POSITION = 'BACK_HISTORY_POSITION';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const DEBUG_TOGGLE_UPDATE_BUBBLE_SHOWN =
  'DEBUG_TOGGLE_UPDATE_BUBBLE_SHOWN';
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const FORWARD_HISTORY_POSITION = 'FORWARD_HISTORY_POSITION';
export const HIDE_PLANS_DIALOG = 'HIDE_PLANS_DIALOG';
export const HIDE_UPSELL_DIALOG = 'HIDE_UPSELL_DIALOG';
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const SET_MENU_SHOWN = 'SET_MENU_SHOWN';
export const SET_MODAL = 'SET_MODAL';
export const SET_UPDATE_BUBBLE_SHOWN = 'SET_UPDATE_BUBBLE_SHOWN';
export const SHOW_PLANS_DIALOG = 'SHOW_PLANS_DIALOG';
export const SHOW_STATUS_BAR = 'SHOW_STATUS_BAR';
export const SHOW_UPSELL_DIALOG = 'SHOW_UPSELL_DIALOG';
