import { addListener } from '@reduxjs/toolkit';
import { useInterpret } from '@xstate/react';
import type { PropsWithChildren } from 'react';
import { createContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { mounted } from '../actions/player/mountSong';
import {
  forward,
  pauseAction,
  playAction,
  seek,
  togglePlaybackAction,
  unmountSongAction,
  updatePlaybackProgress,
} from '../actions/player/player';
import { songEnded } from '../actions/player/songEnded';
import type { Dispatch } from '../reducers/types';
import { playerMachine } from './Player';

type ContextValue = {
  player: ReturnType<typeof useInterpret<typeof playerMachine>>;
};

export const MachineContext = createContext({} as ContextValue);

export type ProviderProps = PropsWithChildren;

export function Provider({ children }: ProviderProps) {
  const player = useInterpret(playerMachine);
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    const clean: (() => void)[] = [];

    clean.push(
      dispatch(
        addListener({
          actionCreator: mounted,
          effect: (action) => {
            player.send({
              payload: action.payload,
              type: 'MOUNTED',
            });
          },
        })
      ) as any as () => void
    );

    clean.push(
      dispatch(
        addListener({
          actionCreator: togglePlaybackAction,
          effect: () => {
            player.send({
              type: 'PLAYBACK_TOGGLED',
            });
          },
        })
      ) as any as () => void
    );

    clean.push(
      dispatch(
        addListener({
          actionCreator: unmountSongAction,
          effect: () => {
            player.send({
              type: 'UNMOUNTED',
            });
          },
        })
      ) as any as () => void
    );

    clean.push(
      dispatch(
        addListener({
          actionCreator: songEnded.pending,
          effect: () => {
            player.send({ type: 'ENDED' });
          },
        })
      ) as any as () => void
    );

    clean.push(
      dispatch(
        addListener({
          actionCreator: playAction,
          effect: () => {
            player.send({ type: 'PLAYED' });
          },
        })
      ) as any as () => void
    );

    clean.push(
      dispatch(
        addListener({
          actionCreator: pauseAction,
          effect: () => {
            player.send({ type: 'PAUSED' });
          },
        })
      ) as any as () => void
    );

    clean.push(
      dispatch(
        addListener({
          actionCreator: forward,
          effect: (action) => {
            player.send({
              payload: {
                add: action.payload,
                cb: (time) => {
                  dispatch(updatePlaybackProgress(time));
                },
              },
              type: 'FORWARDED',
            });
          },
        })
      ) as any as () => void
    );

    clean.push(
      dispatch(
        addListener({
          actionCreator: seek,
          effect: (action) => {
            player.send({
              payload: {
                time: action.payload,
                cb: (time) => {
                  dispatch(updatePlaybackProgress(time));
                },
              },
              type: 'SOUGHT',
            });
          },
        })
      ) as any as () => void
    );

    return () => clean.forEach((fn) => fn());
  }, [dispatch, player]);

  return (
    <MachineContext.Provider value={{ player }}>
      {children}
    </MachineContext.Provider>
  );
}
