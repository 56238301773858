import { DownloadState } from '../../../common/DownloadState';
import type { OfflineSong } from '../../../common/OfflineSong';
import type { Song } from '../../../common/Song';

// const MAX_ARTWORK_SIZE = 2048;
export function getOfflineCandidates(
  fetchedSongs: Song[],
  offlineSongs: Record<string, OfflineSong>
) {
  return fetchedSongs.filter((song) => {
    const offlineSong = offlineSongs[song.audioId];
    const isNotDownloaded =
      offlineSong === undefined ||
      offlineSong.state !== DownloadState.DOWNLOADED;
    const isTranscoding = song.transcoding;
    return isNotDownloaded && !isTranscoding;
  });
}
