import { isEqual } from 'lodash';
import { addOfflineSongs } from '.';
import { DownloadState } from '../../../common/DownloadState';
import { createAppAsyncThunk } from '../../reducers/types';
import { playingSongSelector } from '../../store/selectors';

export const checkPlayingSongDownloadIntegrity = createAppAsyncThunk<
  void,
  void
>('checkPlayingSongDownloadIntegrity', async (_, { dispatch, getState }) => {
  const state = getState();
  const authToken = state.tokens.auth;
  const song = playingSongSelector(state);

  if (!song || !authToken) {
    throw new Error('no song or auth token');
  }

  if (state.downloader.songs[song.audioId]) {
    const offlineSong = state.downloader.offlineSongs[song.audioId];

    const currentCache = await window.nativeAPI.downloader.getCache(
      song,
      authToken
    );

    if (currentCache.state === DownloadState.PENDING) {
      window.nativeAPI.downloader.downloadSongs([song]);
    }

    if (!isEqual(offlineSong, currentCache)) {
      switch (currentCache.state) {
        case DownloadState.PENDING:
        case DownloadState.DOWNLOADED:
          dispatch(addOfflineSongs({ [song.audioId]: currentCache }));
          break;
        default:
          break;
      }
    }
  }
});
