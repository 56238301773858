import { z } from 'zod';
import type { WorkspaceId } from '../../common/Opaques';
import {
  FolderIdSchema,
  PlaylistIdSchema,
  ProjectIdSchema,
  ResourceIdSchema,
  SongIdSchema,
  WorkspaceIdSchema,
} from '../../common/Opaques';
import { PermissionSchema } from '../../common/Permission';
import {
  fromNullish,
  transformToDate,
  transformToNull,
} from '../../common/zod-utils';
import { RoleSchema } from './Role';

export const BackendPlaylistSchema = z
  .object({
    cover_image_path: z.string().nullish().transform(transformToNull),
    created_at: z.string().transform(transformToDate),
    description: z.string().nullish().transform(fromNullish('')),
    playlist_id: PlaylistIdSchema,
    resource_id: ResourceIdSchema,
    role: RoleSchema.nullish().transform(transformToNull),
    song_ids: SongIdSchema.array(),
    title: z.string(),
    deleted_at: z
      .string()
      .transform(transformToDate)
      .nullish()
      .transform(transformToNull),
    cover_image_orig_path: z.string().nullish().transform(transformToNull),
    folder_id: FolderIdSchema.nullish().transform(transformToNull),
    playlist_read_at: z
      .string()
      .transform(transformToDate)
      .nullish()
      .transform(transformToNull),
    project_id: ProjectIdSchema.nullish().transform(transformToNull),
    share_id: z.string().nullish().transform(transformToNull),
    is_unread: z.boolean(),
    public_link_url: z.string().nullish().transform(transformToNull),
    permission_list: z.array(PermissionSchema),
  })
  // .strict()
  .transform((prev) => ({
    coverImageOrigPath: prev.cover_image_orig_path,
    coverImagePath: prev.cover_image_path,
    createdAt: prev.created_at,
    deletedAt: prev.deleted_at,
    description: prev.description,
    docType: 'playlist' as const,
    folderId: prev.folder_id,
    isUnread: prev.is_unread,
    permissionList: prev.permission_list,
    playlistId: prev.playlist_id,
    playlistReadAt: prev.playlist_read_at,
    projectId: prev.project_id,
    publicLinkUrl: prev.public_link_url,
    resourceId: prev.resource_id,
    role: prev.role,
    shareId: prev.share_id,
    songIds: prev.song_ids,
    title: prev.title,
  }));

export type Playlist = z.infer<typeof BackendPlaylistSchema> & {
  workspaceId: WorkspaceId;
};

export const defaultPlaylist: Playlist = {
  coverImageOrigPath: null,
  coverImagePath: null,
  createdAt: new Date('2023-02-06T22:01:56.389Z'),
  deletedAt: null,
  description: '',
  docType: 'playlist',
  folderId: null,
  isUnread: false,
  permissionList: [],
  playlistId: PlaylistIdSchema.parse('pl1'),
  playlistReadAt: null,
  projectId: null,
  publicLinkUrl: null,
  resourceId: ResourceIdSchema.parse('I'),
  role: 'playlist_owner',
  shareId: null,
  songIds: [],
  title: 'Workspace Playlist',
  workspaceId: WorkspaceIdSchema.parse('w1'),
};

export const GetPlaylistListResponseSchema = z.object({
  records: BackendPlaylistSchema.array(),
  size: z.number(),
});

export type GetPlaylistListResponse = z.infer<
  typeof GetPlaylistListResponseSchema
>;

export function ParsePlaylist(
  value: unknown,
  workspaceId: WorkspaceId
): Playlist {
  return { ...BackendPlaylistSchema.parse(value), workspaceId };
}
