import { createAction } from '@reduxjs/toolkit';
import type { OfflineSong } from '../../../common/OfflineSong';
import type { PlaylistId, SongId } from '../../../common/Opaques';
import { transformEmptyArray } from '../../../common/zod-utils';
import { ErrorCodes } from '../../constants/errors';
import { createAppAsyncThunk } from '../../reducers/types';
import { api } from '../../services/api/api';
import {
  findPlaylistInAnyWorkspaceSelector,
  findPlaylistInSelectedWorkspaceSelector,
} from '../../store/selectors';
import { tryCatchFinallyReport } from '../../utils/error-utils';
import { getOfflineCandidates } from './getOfflineCandidates';

export const addOfflineSongs =
  createAction<Record<SongId, OfflineSong>>('addOfflineSongs');

export const setOfflineSongs =
  createAction<Record<SongId, OfflineSong>>('setOfflineSongs');

export const clearOfflineSongs = createAppAsyncThunk<void, void>(
  'clearOfflineSongs',
  async () => {
    // Delete stream cache
    const keys = await caches.keys();
    const songKeys = keys.filter((key) => key.substr(0, 5) === 'song-');
    const deleteTasks = songKeys.map((key) => {
      return caches.delete(key);
    });
    await Promise.all(deleteTasks);

    try {
      // Delete original files
      await window.nativeAPI.downloader.deleteAllOriginalSongFiles();
      // // eslint-disable-next-line no-alert
      // alert('Song cache cleared');
    } catch (err) {
      console.error(err);
    }
  }
);

export const hydrateOfflineSongs = createAppAsyncThunk(
  'hydrateOfflineSongs',
  async (_, { dispatch, getState }) => {
    return tryCatchFinallyReport(ErrorCodes.ERROR_FETCH_SONGS, async () => {
      const state = getState();
      const authToken = state.tokens.auth;
      if (!authToken) {
        throw new Error('No Auth');
      }

      const workspaces = transformEmptyArray(
        api.endpoints.getWorkspaces.select()(state).data
      );

      const index = Object.keys(state.downloader.songs);
      const songs_to_download = workspaces
        .flatMap((workspace) =>
          transformEmptyArray(
            api.endpoints.getWorkspaceSongs.select(workspace.stringId)(state)
              .data
          ).filter((song) => state.downloader.songs[song.audioId])
        )
        .sort((a, b) => index.indexOf(a.audioId) - index.indexOf(b.audioId));

      const offlineSongs = await window.nativeAPI.downloader.getAllCache(
        songs_to_download,
        authToken
      );

      dispatch(setOfflineSongs(offlineSongs));
      const candidates = getOfflineCandidates(songs_to_download, offlineSongs);

      if (candidates.length > 0) {
        window.nativeAPI.downloader.downloadSongs(candidates);
      }
    });
  }
);

// check if it is better to prune by checking all the existing playlists instead of explicitly passing the playlist ids
export const prunePlaylist = createAction<PlaylistId[]>('prunePlaylist');

export const pruneSongs = createAppAsyncThunk(
  'pruneSongs',
  async (_, { getState }) => {
    const state = getState();

    const all_workspaces = transformEmptyArray(
      api.endpoints.getWorkspaces.select()(state).data
    );

    const all_playlist_in_all_workspaces = all_workspaces.flatMap((workspace) =>
      transformEmptyArray(
        api.endpoints.getWorkspacePlaylists.select(workspace.stringId)(state)
          .data
      )
    );

    const downloaded_playlists_that_do_not_exist =
      state.downloader.playlists.filter(
        (playlistId) =>
          !all_playlist_in_all_workspaces.find(
            (playlist) => playlist.playlistId === playlistId
          )
      );

    const all_songs_in_all_workspaces = all_workspaces.flatMap((workspace) =>
      transformEmptyArray(
        api.endpoints.getWorkspaceSongs.select(workspace.stringId)(state).data
      )
    );

    const all_downloaded_songs = state.downloader.playlists.flatMap(
      (playlistId) =>
        transformEmptyArray(
          findPlaylistInAnyWorkspaceSelector(playlistId)(state)?.songIds
        )
    );

    const downloaded_songs_that_do_not_exist = Object.keys(
      state.downloader.songs
    ).filter(
      (songId) =>
        !all_songs_in_all_workspaces.find((song) => song.audioId === songId) ||
        !all_downloaded_songs.includes(songId as any as SongId)
    );

    return {
      removed_playlists: downloaded_playlists_that_do_not_exist,
      removed_songs: downloaded_songs_that_do_not_exist,
    };
  }
);

export const downloadPlaylist = createAppAsyncThunk(
  'downloadPlaylist',
  async (playlistId: PlaylistId, { getState }) => {
    const state = getState();
    const playlist =
      findPlaylistInSelectedWorkspaceSelector(playlistId)(state)!;

    return {
      songs: playlist.songIds,
      playlistId,
    };
  }
);

export const pruneFiles = createAppAsyncThunk(
  'pruneFiles',
  async (_, { getState, dispatch }) => {
    // TODO: there is a race condition here, if we delete a song that is in queue to be downloaded, and another if the song is currently being downloaded
    if (window.nativeAPI.isElectron) {
      const state = getState();

      await window.nativeAPI.downloader.deleteAllSongsExcept(
        Object.keys(state.downloader.songs) as any as SongId[]
      );

      await dispatch(hydrateOfflineSongs());
    }
  }
);
