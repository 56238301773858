import { isAxiosError } from 'axios';
import { match } from 'ts-pattern';
import type { FolderId } from '../../../common/Opaques';
import type { Song } from '../../../common/Song';
import { createAppAsyncThunk } from '../../reducers/types';
import { api } from '../../services/api/api';
import type { Project, Workspace } from '../../services/api/types/Workspace';
import type { Folder } from '../../types/Folder';
import type { Playlist } from '../../types/Playlist';
import { allChildrenFolders } from '../../utils/playlist-tree-utils';
import { setError } from './setError';

export const moveResources = createAppAsyncThunk<
  void,
  {
    draggingResources: (Folder | Playlist | Song)[];
    droppingResource: Project | Folder | Playlist | Workspace;
  }
>(
  'dragAndDropCall',
  async ({ draggingResources, droppingResource }, { dispatch }) => {
    const workspaceId = match(droppingResource)
      .with({ docType: 'workspace' }, (x) => x.stringId)
      .otherwise((x) => x.workspaceId);

    const patches: ReturnType<ReturnType<typeof api.util.updateQueryData>>[] =
      [];
    draggingResources.forEach((draggingResource) => {
      if (draggingResource.docType === 'folder') {
        const draggingFolders: FolderId[] = [];

        patches.push(
          dispatch(
            api.util.updateQueryData(
              'getWorkspaceFolders',
              workspaceId,
              (draft) => {
                draggingFolders.push(
                  draggingResource.folderId,
                  ...allChildrenFolders(draft, draggingResource.folderId)
                );

                draft.forEach((folder) => {
                  if (folder.folderId === draggingResource.folderId) {
                    if (droppingResource.docType === 'workspace') {
                      folder.parentFolderId = null;
                      folder.projectId = null;
                    }
                    if (droppingResource.docType === 'project') {
                      folder.projectId = droppingResource.stringId;
                      folder.parentFolderId = null;
                    }
                    if (droppingResource.docType === 'folder') {
                      folder.projectId = droppingResource.projectId;
                      folder.parentFolderId = droppingResource.folderId;
                    }
                  } else if (draggingFolders.includes(folder.folderId)) {
                    if (droppingResource.docType === 'workspace') {
                      folder.projectId = null;
                    }
                    if (droppingResource.docType === 'project') {
                      folder.projectId = droppingResource.stringId;
                    }
                    if (droppingResource.docType === 'folder') {
                      folder.projectId = droppingResource.projectId;
                    }
                  }
                });
              }
            )
          )
        );
        patches.push(
          dispatch(
            api.util.updateQueryData(
              'getWorkspacePlaylists',
              workspaceId,
              (draft) => {
                draft.forEach((playlist) => {
                  if (
                    playlist.folderId !== null &&
                    draggingFolders.includes(playlist.folderId)
                  ) {
                    if (droppingResource.docType === 'workspace') {
                      playlist.projectId = null;
                    }
                    if (droppingResource.docType === 'project') {
                      playlist.projectId = droppingResource.stringId;
                    }
                    if (droppingResource.docType === 'folder') {
                      playlist.projectId = droppingResource.projectId;
                    }
                  }
                });
              }
            )
          )
        );
      }
      if (draggingResource.docType === 'playlist') {
        patches.push(
          dispatch(
            api.util.updateQueryData(
              'getWorkspacePlaylists',
              workspaceId,
              (draft) => {
                draft.forEach((playlist) => {
                  if (playlist.playlistId === draggingResource.playlistId) {
                    if (droppingResource.docType === 'workspace') {
                      playlist.projectId = null;
                      playlist.folderId = null;
                    }
                    if (droppingResource.docType === 'project') {
                      playlist.projectId = droppingResource.stringId;
                      playlist.folderId = null;
                    }
                    if (droppingResource.docType === 'folder') {
                      playlist.projectId = droppingResource.projectId;
                      playlist.folderId = droppingResource.folderId;
                    }
                  }
                });
              }
            )
          )
        );
      }
    });

    try {
      await dispatch(
        api.endpoints.moveResource.initiate({
          resourceIds: draggingResources.map((source) => source.resourceId),
          targetResourceId: droppingResource.resourceId,
          workspaceId,
        })
      );
    } catch (e) {
      patches.forEach((p) => p.undo());
      if (isAxiosError(e)) {
        dispatch(setError(e.response?.data.message || e.response));
      }
      throw e;
    }
  }
);
