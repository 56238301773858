import { createSelector } from '@reduxjs/toolkit';
import { head, lookup } from 'fp-ts/lib/Array';
import {
  chain,
  flatMap,
  fromNullable,
  match,
  some,
  toUndefined,
} from 'fp-ts/lib/Option';
import { constFalse, flow, pipe } from 'fp-ts/lib/function';
import { isEqual } from 'lodash';
import type {
  PlaylistId,
  ProjectId,
  SongId,
  WorkspaceId,
} from '../../common/Opaques';
import type { Song } from '../../common/Song';
import { transformEmptyArray } from '../../common/zod-utils';
import type {
  ControlStateType,
  PlayerQueue,
  PlayerQueueItem,
} from '../reducers/types';
import { api } from '../services/api/api';
import type { Project, Workspace } from '../services/api/types/Workspace';
import { defaultOrder, defaultPlaylistOrder } from '../types/FileListSort';
import type { Playlist } from '../types/Playlist';
import checkPlaylistIfOwned from '../utils/playlist-utils';
import { matchPossiblePaths } from '../utils/route-utils';

export function authTokenSelector(controlState: ControlStateType) {
  return controlState.tokens.auth;
}

export function loggedUserSelector(controlState: ControlStateType) {
  return controlState.loggedUser;
}

export function selectedWorkspacePlaylistsSelector(
  state: ControlStateType
): Playlist[] {
  const { selectedWorkspaceId } = state.workspace;
  return transformEmptyArray(
    selectedWorkspaceId &&
      pipe(
        api.endpoints.getWorkspacePlaylists.select(selectedWorkspaceId)(
          state as any
        ).data
      )
  );
}

export function findPlaylistInSelectedWorkspaceSelector(
  playlistId: PlaylistId
) {
  return flow(selectedWorkspacePlaylistsSelector, (playlists) =>
    playlists.find((p) => p.playlistId === playlistId)
  );
}

export function findPlaylistInAnyWorkspaceSelector(playlistId: PlaylistId) {
  return (state: ControlStateType): Playlist | undefined => {
    const workspaces = transformEmptyArray(
      api.endpoints.getWorkspaces.select()(state).data
    );

    for (let index = 0; index < workspaces.length; index += 1) {
      const workspace = workspaces[index]!;

      const playlist = transformEmptyArray(
        api.endpoints.getWorkspacePlaylists.select(workspace.stringId)(state)
          .data
      ).find((p) => p.playlistId === playlistId);

      if (playlist) {
        return playlist;
      }
    }
    return undefined;
  };
}

export function findSelectedWorkspaceProjectPlaylistsSelector(
  projectId: ProjectId
) {
  return flow(selectedWorkspacePlaylistsSelector, (playlists) =>
    playlists.filter((p) => p.projectId === projectId)
  );
}

export function selectedWorkspaceSelectedPlaylistSelector(
  state: ControlStateType
): Playlist | undefined {
  return (
    state.songs.selectedPlaylistId &&
    findPlaylistInSelectedWorkspaceSelector(state.songs.selectedPlaylistId)(
      state
    )
  );
}

/**
 * @deprecated
 */
export function isOwnerSelector(state: ControlStateType) {
  const selectedPlaylist = selectedWorkspaceSelectedPlaylistSelector(state);

  return selectedPlaylist && state.loggedUser
    ? checkPlaylistIfOwned(selectedPlaylist, state.loggedUser.userId)
    : false;
}

export function selectedFolderSelector(state: ControlStateType) {
  const { selectedWorkspaceId } = state.workspace;
  return (
    selectedWorkspaceId &&
    api.endpoints.getWorkspaceFolders
      .select(selectedWorkspaceId)(state as any)
      .data?.find(({ folderId }) => folderId === state.songs.selectedFolderId)
  );
}

export function selectedWorkspaceSelectedProjectSelector(
  state: ControlStateType
): Project | undefined {
  return state.workspace.selectedWorkspaceId &&
    state.workspace.selectedProjectId
    ? pipe(
        api.endpoints.getWorkspaceProjectList
          .select(state.workspace.selectedWorkspaceId)(state as any)
          .data?.find(
            (item) => item.stringId === state.workspace.selectedProjectId
          ) ?? undefined
      )
    : undefined;
}

export function findProjectSelector(
  workspaceId: WorkspaceId,
  projectId: ProjectId
) {
  return (state: ControlStateType): Project | undefined =>
    pipe(
      api.endpoints.getWorkspaceProjectList
        .select(workspaceId)(state as any)
        .data?.find((item) => item.stringId === projectId) ?? undefined
    );
}

export function workspaceSongsSelector(state: ControlStateType): Song[] {
  return transformEmptyArray(
    state.workspace.selectedWorkspaceId &&
      api.endpoints.getWorkspaceSongs.select(
        state.workspace.selectedWorkspaceId
      )(state).data
  );
}

export function findWorkspaceBySongIdSelector(ids: SongId[]) {
  return (state: ControlStateType): Workspace[] => {
    const workspaces = transformEmptyArray(
      api.endpoints.getWorkspaces.select()(state as any).data
    );

    return workspaces.filter((workspace) => {
      const songs = transformEmptyArray(
        api.endpoints.getWorkspaceSongs.select(workspace.stringId)(state as any)
          .data
      );

      return songs.some((song) => ids.includes(song.audioId));
    });
  };
}

export const songsInAnyWorkspaceSelector = createSelector(
  (state: ControlStateType) => state,
  (_state: ControlStateType, queue: PlayerQueue) => queue,
  (state, queue): Song[] => {
    const ids = queue.map((item) => item[1]);
    return queue.flatMap((item) =>
      transformEmptyArray(
        api.endpoints.getWorkspaceSongs.select(
          item[0].match.params.workspaceId
        )(state as any).data
      ).filter((song) => ids.includes(song.audioId))
    );
  }
);

export function playingSongSelector(state: ControlStateType): Song | undefined {
  return pipe(
    state.player.playingSongIndex,
    flatMap((x) => lookup(x, state.player.queueSongIds)),
    flatMap((queueItem) =>
      head(songsInAnyWorkspaceSelector(state, [queueItem]))
    ),
    toUndefined
  );
}

export function playingQueueItemSelector(
  state: ControlStateType
): PlayerQueueItem | undefined {
  return pipe(
    state.player.playingSongIndex,
    flatMap((x) => lookup(x, state.player.queueSongIds)),
    toUndefined
  );
}

export function playingSongIdSelector(
  state: ControlStateType
): SongId | undefined {
  return playingQueueItemSelector(state)?.[1];
}

export function isPlayingFromCurrentPathSelector(
  state: ControlStateType
): boolean {
  return pipe(
    state.player.playingSongIndex,
    chain((i) => lookup(i, state.player.queueSongIds)),
    match(constFalse, (item) =>
      isEqual(
        pipe(state.router.location, fromNullable, chain(matchPossiblePaths)),
        some(item[0])
      )
    )
  );
}

export function songAutocompleteOptionsSelector(state: ControlStateType) {
  return state.songs.autocompleteOptions;
}

export const routeOrderSelector = createSelector(
  (state: ControlStateType) => state.router.location?.pathname,
  (state: ControlStateType) => state.songs.routeScopedState,
  (state: ControlStateType) => state.songs.selectedPlaylistId,
  (pathname, routeScopedState, selectedPlaylistId) => {
    const order = selectedPlaylistId ? defaultPlaylistOrder : defaultOrder;
    return pathname ? routeScopedState[pathname]?.sort ?? order : order;
  }
);

// export function userStoreFavoritesSelector(state: ControlStateType): string[] {
//   return (
//     api.endpoints.getStore.select()(state as any).data?.['electron-pinned'] ??
//     []
//   );
// }
