import { sortBy } from 'fp-ts/lib/Array';
import { Ord as BooleanOrd } from 'fp-ts/lib/boolean';
import { Ord as DateOrd } from 'fp-ts/lib/Date';
import { pipe } from 'fp-ts/lib/function';
import { Ord as NumberOrd } from 'fp-ts/lib/number';
import type { Option } from 'fp-ts/lib/Option';
import { fromNullable } from 'fp-ts/lib/Option';
import { contramap, fromCompare, reverse } from 'fp-ts/lib/Ord';
import { Ord as StringOrd } from 'fp-ts/lib/string';
import { intersection, without } from 'lodash';
import type { Context } from 'macos-multi-select';
import { match } from 'ts-pattern';
import { DownloadState } from '../../common/DownloadState';
import type { OfflineSong } from '../../common/OfflineSong';
import type { SongId, UserId } from '../../common/Opaques';
import {
  ResourceIdSchema,
  SongIdSchema,
  WorkspaceIdSchema,
} from '../../common/Opaques';
import type { Song } from '../../common/Song';
import type { UploadingSong, UploadsStateType } from '../reducers/types';
import type { FileListSort } from '../types/FileListSort';
import type { Playlist } from '../types/Playlist';
import { notEmpty } from './array-utils';

export function getSongKind(song: Song, isOffline: boolean) {
  const codecName = song.metadata?.codecName;
  const formatName = song.metadata?.formatName;
  const { origPath, metadataFormat } = song;

  let kind: string | null = null;

  if (!isOffline) {
    kind = 'STREAM';
  } else if (origPath == null) {
    kind = 'AAC';
  } else if (codecName != null) {
    const parts = codecName.split('_');
    const codecFirstPart =
      parts[0] !== undefined ? parts[0].toUpperCase() : codecName.toUpperCase();
    if (codecFirstPart === 'PCM') {
      kind = formatName ? formatName.toUpperCase() : codecFirstPart;
    } else {
      kind = codecFirstPart;
    }
  } else if (metadataFormat === 'WAVE') {
    kind = 'WAV';
  } else {
    const components = origPath.split('/');
    const lastComponent = components[components.length - 1];
    if (lastComponent) {
      const fileNameParts = lastComponent.split('.');
      const extension = fileNameParts[fileNameParts.length - 1];
      if (extension) {
        kind = extension.toUpperCase();
      }
    }
  }

  return kind;
}

export function getSongKindColor(kind: string) {
  switch (kind) {
    case 'FLAC':
      return '#7FA563';
    case 'MP3':
      return '#009AFF';
    case 'AIFF':
    case 'AIF':
      return '#2D83FF';
    case 'AAC':
      return '#B66AB9';
    case 'WAV':
      return '#17A07A';
    case 'M4A':
      return '#17A07A';
    case 'OGG':
    case 'VORBIS':
      return '#5C8BEB';
    case 'WMA':
    case 'WMAV2':
      return '#E17251';
    case 'ALAC':
      return '#99D8FD';
    case 'PCM':
      return '#D3943B';
    default:
      return null;
  }
}

export function formatBitrate(bitRate: string) {
  const bitrateInt = parseInt(bitRate, 10);
  // eslint-disable-next-line no-irregular-whitespace
  return bitrateInt ? `${Math.round(bitrateInt / 1000)} kbps` : '';
}

// export function getCachePath() {
//   const cachePath = window.nativeAPI.app.getPath('cache');
//   const appCachePath = `${cachePath}/ControlElectron`;
//   if (!window.nativeAPI.existsSync(appCachePath)) {
//     window.nativeAPI.mkdirSync(appCachePath);
//   }
//   return appCachePath;
// }

// export function getUserDataPath() {
//   const userDataPath = window.nativeAPI.app.getPath('userData');
//   return userDataPath;
// }

// export function getSongsCacheDir() {
//   const cachePath = getCachePath();
//   const songCachePath = `${cachePath}/Songs`;
//   if (!window.nativeAPI.existsSync(songCachePath)) {
//     window.nativeAPI.mkdirSync(songCachePath);
//   }
//   return songCachePath;
// }

// export function getSongCacheFile(song: Song) {
//   const appCachePath = getSongsCacheDir();
//   const songPath = window.nativeAPI.path.join(appCachePath, song.audioId);
//   if (!window.nativeAPI.existsSync(songPath)) {
//     window.nativeAPI.mkdirSync(songPath);
//   }

//   const ext = song.originalFilename
//     ? window.nativeAPI.path.extname(song.originalFilename)
//     : '';
//   const fileName = song.title
//     ? window.nativeAPI.path.basename(song.title, ext) + ext
//     : song.audioId;
//   const filePath = window.nativeAPI.path.join(songPath, fileName);
//   return filePath;
// }

// export function getImportingSongCacheFile(
//   id: string,
//   importingFilePath: string
// ) {
//   const appCachePath = getSongsCacheDir();

//   const dir = window.nativeAPI.path.join(appCachePath, id);
//   if (!window.nativeAPI.existsSync(dir)) {
//     window.nativeAPI.mkdirSync(dir);
//   }
//   const name = window.nativeAPI.path.basename(importingFilePath);
//   const filePath = window.nativeAPI.path.join(dir, name);
//   return filePath;
// }

export function selectedSongsWithIndexOrder(selectionContext: Context<SongId>) {
  return intersection(selectionContext.index, selectionContext.selected);
}

function previousNotInGroup<T>(
  array: T[],
  group: T[],
  element: T | undefined
): T | undefined {
  let x: T | undefined = element;

  while (x && group.includes(x)) {
    const currentIndex = array.indexOf(x);
    x = array[currentIndex - 1];
  }

  return x;
}

export function reorderSongs(
  songs: SongId[],
  moving:
    | { kind: 'single'; id: SongId }
    | { kind: 'selection'; context: Context<SongId> },
  after: number
): SongId[] {
  if (moving.kind === 'single') {
    const songIds = [...songs];
    const draggingSongId = moving.id;
    const draggingIndex = songIds.indexOf(draggingSongId);
    songIds.splice(draggingIndex, 1);
    songIds.splice(after + (draggingIndex > after ? 1 : 0), 0, draggingSongId);
    return songIds;
  }

  const safeAfter = previousNotInGroup(
    songs,
    moving.context.selected,
    songs[after]
  );

  const ordered = selectedSongsWithIndexOrder(moving.context);

  const clearIndex = without(songs, ...ordered);

  if (safeAfter === undefined) {
    return ordered.concat(clearIndex);
  }
  const safeIndex = clearIndex.indexOf(safeAfter);
  clearIndex.splice(safeIndex + 1, 0, ...ordered);

  return clearIndex;
}

export function getSongsOfflinePaths(
  offlineSongs: Record<string, OfflineSong>,
  songs: Song[]
): string[] {
  return songs.reduce<string[]>((acc, song) => {
    const offlineSong = offlineSongs[song.audioId];
    if (
      offlineSong &&
      offlineSong.state === DownloadState.DOWNLOADED &&
      offlineSong.payload.type === 'file'
    ) {
      acc.push(offlineSong.payload.path);
    }
    return acc;
  }, []);
}

export function findSongById(songs: Song[], audioId: SongId | undefined) {
  return songs.find((song: Song) => song.audioId === audioId);
}

export function getSongById(songs: Song[]) {
  return (audioId: SongId): Option<Song> => {
    return fromNullable(findSongById(songs, audioId));
  };
}

// export function getSongPlaybackType(song: Song): 'file' | 'stream' {
//   return song.origPath ? 'file' : 'stream';
// }

// export function makeCacheKey(song: Song): string {
//   return `song-${song.audioId}`;
// }

// const validExtensions = [
//   '.FLAC',
//   '.MP3',
//   '.AIFF',
//   '.AIF',
//   '.AAC',
//   '.WAV',
//   '.M4A',
//   '.OGG',
//   '.VORBIS',
//   '.WMA',
//   '.WMAV2',
//   '.ALAC',
//   '.PCM',
// ];

// export function validImportPath(filePath: string) {
//   try {
//     return validExtensions.includes(
//       window.nativeAPI.path.extname(filePath).toUpperCase()
//     );
//   } catch (err) {
//     return false;
//   }
// }

export function songsFromIds(songs: Song[], ids: string[]): Song[] {
  return ids
    .map((id) => songs.find((song) => song.audioId === id))
    .filter(notEmpty);
}

export function filterSongPlaylists(
  playlists: Playlist[],
  song?: Song
): Playlist[] {
  let filteredPlaylists: Playlist[];
  if (song) {
    filteredPlaylists = playlists.filter((playlist) => {
      return playlist.songIds.indexOf(song.audioId) !== -1;
    });
  } else {
    filteredPlaylists = [];
  }
  return filteredPlaylists;
}

export function songFromUploadingSong(upload: UploadingSong): Song {
  return {
    docType: 'song',
    resourceId: ResourceIdSchema.parse(''), // TODO: add info in upload
    workspaceId: WorkspaceIdSchema.parse(''), // TODO: add info in upload
    audioId: upload.uploadQueueItem.audioId,
    coverImagePath: null,
    createdAt: new Date(),
    deletedAt: null,
    duration: null,
    metadata: {
      bitRate: null,
      channelLayout: null,
      channels: null,
      codecLongName: null,
      codecName: null,
      duration: null,
      formatLongName: null,
      formatName: null,
      metadataComments: null,
      metadataContains_music: null,
      metadataCopyrightDate: null,
      metadataDescription: null,
      metadataDistributor: null,
      metadataExplicit: null,
      metadataGrouping: null,
      metadataIsrc: null,
      metadataIswc: null,
      metadataKey: null,
      metadataLanguage: null,
      metadataLicense: null,
      metadataMasterOwner: null,
      metadataOrder: null,
      metadataPline: null,
      metadataPublisher: null,
      metadataRecordLabel: null,
      metadataReleaseDate: null,
      metadataReleaseTitle: null,
      metadataUpcBarcode: null,
      sampleFmt: null,
      sampleRate: null,
      size: null,
    },
    metadataAlbum: null,
    metadataAlbumArtist: null,
    metadataArtist: null,
    metadataRating: null,
    metadataBpm: null,
    metadataCodecProfile: null,
    metadataComposer: null,
    metadataCopyright: null,
    metadataFormat: null,
    metadataGenre: null,
    metadataTrackCount: null,
    metadataTrackNumber: null,
    metadataYear: null,
    origPath: upload.uploadQueueItem.filePath, // to let the cache type be file instead of stream
    originalFilename: null,
    path: null,
    size: upload.uploadQueueItem.size, // to compare with cache size
    title: upload.uploadQueueItem.title,
    transcoding: false,
    uploadedAt: new Date(),
    uploading: true,
    uploader: {
      artistName: '',
      avatarUrl: null,
      createdAt: new Date(),
      email: '',
      location: null,
      name: '',
      occupation: null,
      userId: '1' as UserId,
    },
    authenticatedOrigPath: '',
    coverImageOrigPath: '',
    isFavorite: false,
    keyPath: '',
    m3u8Path: '',
    metadataBitrate: 0,
    metadataChannels: 0,
    metadataSampleRate: 0,
    metadataTitle: null,
    versionId: 0,
    webPlaybackPath: '',
    commentCount: 0,
    encrypted: null,
    favoritedAt: null,
    playCount: 0,
    projectId: 0,
    permissionList: [],
  };
}

export const emptySong: Song = {
  docType: 'song',
  resourceId: ResourceIdSchema.parse(''),
  workspaceId: WorkspaceIdSchema.parse(''),
  audioId: SongIdSchema.parse('1'),
  coverImagePath: null,
  createdAt: new Date('2023-03-09T08:20:56.887Z'),
  deletedAt: null,
  duration: 37.62,
  // isFavorite: false,
  metadata: {
    bitRate: '320000',
    channelLayout: null,
    channels: 'stereo',
    codecLongName: '2',
    codecName: 'MP3 (MPEG audio layer 3)',
    duration: 0,
    formatLongName: '37.616325',
    formatName: 'MP2/3 (MPEG audio layer 2/3)',
    metadataComments: null,
    metadataContains_music: null,
    metadataCopyrightDate: null,
    metadataDescription: null,
    metadataDistributor: null,
    metadataExplicit: null,
    metadataGrouping: null,
    metadataIsrc: null,
    metadataIswc: null,
    metadataKey: null,
    metadataLanguage: null,
    metadataLicense: null,
    metadataMasterOwner: null,
    metadataOrder: null,
    metadataPline: null,
    metadataPublisher: null,
    metadataRecordLabel: null,
    metadataReleaseDate: null,
    metadataReleaseTitle: null,
    metadataUpcBarcode: null,
    sampleFmt: 'mp3',
    sampleRate: 'fltp',
    size: '44100',
  },
  metadataAlbum: 'Royalty Free',
  metadataAlbumArtist: null,
  metadataArtist: 'Kevin MacLeod',
  metadataBpm: null,
  metadataCodecProfile: 'CBR',
  metadataComposer: 'Kevin MacLeod',
  metadataCopyright: null,
  metadataFormat: 'MPEG',
  metadataGenre: 'TV & Film',
  metadataTrackNumber: null,
  metadataYear: 2010,
  metadataRating: null,
  metadataTrackCount: null,
  origPath:
    'https://dvr722unfp578.cloudfront.net/audio/orig/e5358b0f-97b9-4747-84e0-81188cd6ad3f.mp3',
  originalFilename: 'There It Is 39.mp3',
  path: 'https://trn-ctrl-api-staging-ws.herokuapp.com/audio/06b355e9-7/m3u8/48875',
  size: 1506892,
  title: 'There It Is 39.mp3',
  transcoding: false,
  uploadedAt: new Date('2023-03-09T08:21:00.000Z'),
  uploading: false,
  uploader: {
    artistName: '',
    avatarUrl: null,
    createdAt: new Date('2023-03-09T08:20:56.887Z'),
    email: '',
    location: null,
    name: '',
    occupation: null,
    userId: '1' as UserId,
  },
  authenticatedOrigPath: '',
  coverImageOrigPath: '',
  isFavorite: false,
  keyPath: '',
  m3u8Path: '',
  metadataBitrate: 0,
  metadataChannels: 0,
  metadataSampleRate: 0,
  metadataTitle: null,
  versionId: 0,
  webPlaybackPath: '',
  commentCount: 0,
  encrypted: null,
  favoritedAt: null,
  playCount: 0,
  projectId: 0,
  permissionList: [],
};

/**
 * Uploads go first and in creation order
 */
function UploadOrd(uploads: UploadsStateType) {
  return fromCompare((a: Song, b: Song) => {
    const aa =
      uploads.songs[a.audioId]?.createdAt.getTime() ?? Number.MAX_SAFE_INTEGER;
    const bb =
      uploads.songs[b.audioId]?.createdAt.getTime() ?? Number.MAX_SAFE_INTEGER;

    if (aa === bb) {
      return 0;
    }
    if (aa > bb) {
      return 1;
    }
    return -1;
  });
}

/**
 * Uploads go first
 */
const UploadingOrd = pipe(
  reverse(BooleanOrd),
  contramap((p: Song) => p.uploading)
);

function TitleOrd(order: FileListSort) {
  return pipe(
    order.sortOrd === 'asc' ? StringOrd : reverse(StringOrd),
    contramap((item: Song) => item.title.toLocaleLowerCase())
  );
}

function CreatedAtOrd(order: FileListSort) {
  return pipe(
    order.sortOrd === 'desc' ? DateOrd : reverse(DateOrd),
    contramap((item: Song) => item.createdAt)
  );
}

export function sortSongByOrder(
  order: FileListSort,
  uploads: UploadsStateType
) {
  return (songs: Song[]): Song[] =>
    match(order)
      .with({ sortBy: 'duration' }, ({ sortOrd }) =>
        pipe(
          songs,
          sortBy([
            UploadOrd(uploads),
            UploadingOrd,
            pipe(
              sortOrd === 'asc' ? NumberOrd : reverse(NumberOrd),
              contramap((item: Song) => Number(item.metadata.duration) ?? 0)
            ),
            TitleOrd(order),
            CreatedAtOrd(order),
          ])
        )
      )
      .with({ sortBy: 'createdAt' }, () =>
        pipe(
          songs,
          sortBy([UploadOrd(uploads), UploadingOrd, CreatedAtOrd(order)])
        )
      )
      .with({ sortBy: 'title' }, () =>
        pipe(
          songs,
          sortBy([
            UploadOrd(uploads),
            UploadingOrd,
            TitleOrd(order),
            CreatedAtOrd(order),
          ])
        )
      )
      .with({ sortBy: 'artist' }, ({ sortOrd }) =>
        pipe(
          songs,
          sortBy([
            UploadOrd(uploads),
            UploadingOrd,
            pipe(
              sortOrd === 'asc' ? StringOrd : reverse(StringOrd),
              contramap(
                (item: Song) => item.metadataArtist?.toLocaleLowerCase() ?? ''
              )
            ),
            TitleOrd(order),
            CreatedAtOrd(order),
          ])
        )
      )
      .with({ sortBy: 'album' }, ({ sortOrd }) =>
        pipe(
          songs,
          sortBy([
            UploadOrd(uploads),
            UploadingOrd,
            pipe(
              sortOrd === 'asc' ? StringOrd : reverse(StringOrd),
              contramap(
                (item: Song) => item.metadataAlbum?.toLocaleLowerCase() ?? ''
              )
            ),
            TitleOrd(order),
            CreatedAtOrd(order),
          ])
        )
      )
      .otherwise(() => songs);
}

export const memptyContext: Context<SongId> = {
  adjacentPivot: undefined,
  index: [],
  selected: [],
};

export function getSongSubtitle(song?: Song, offlineSong?: OfflineSong) {
  const artistAlbum: string[] = [];
  if (song) {
    if (song.metadataArtist) {
      artistAlbum.push(song.metadataArtist);
    }
    if (song.metadataAlbum) {
      artistAlbum.push(song.metadataAlbum);
    }
  }

  let subtitle = artistAlbum.join(' - ');

  if (song) {
    const kind = getSongKind(song, Boolean(offlineSong));
    if (subtitle.length > 0) {
      subtitle += ' · ';
    }
    subtitle += kind;
    if (song.metadata?.bitRate) {
      const bitrate = formatBitrate(song.metadata.bitRate);
      subtitle += ` ${bitrate}`;
    }
  }
  return subtitle;
}
