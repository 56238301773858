import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ThunkApiConfig } from '../../reducers/types';
import { mountSong } from './mountSong';
import { songEndedAction } from './player';

export const songEnded = createAsyncThunk<void, void, ThunkApiConfig>(
  'songs/songEnded',
  async (_, { dispatch, getState }) => {
    dispatch(songEndedAction());
    const state = getState();
    dispatch(
      mountSong([
        {
          playingSongIndex: state.player.playingSongIndex,
          repeatMode: state.player.repeatMode,
        },
        state.player.isPlaying,
      ])
    );
  }
);
