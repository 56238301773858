import { createAction } from '@reduxjs/toolkit';
import type { MountData } from '../../machines/Player';
import type { PlayerStateType } from '../../reducers/types';
import { createAppAsyncThunk } from '../../reducers/types';
import { playingSongSelector } from '../../store/selectors';
import { lookupOrUndefined } from '../../utils/array-utils';
import { checkPlayingSongDownloadIntegrity } from '../downloader/checkSongDownloadIntegrity';
import { mountSongAction } from './player';

// TODO: add this action to the right place
export const mounted = createAction<MountData>('player/mounted');

export const mountSong = createAppAsyncThunk<
  void,
  [
    Pick<PlayerStateType, 'playingSongIndex' | 'repeatMode'> &
      Partial<Pick<PlayerStateType, 'playbackProgress'>>,
    boolean
  ]
>(
  'songs/mountSong',
  async (
    [{ playingSongIndex, repeatMode, playbackProgress = 0 }, autoPlay],
    { dispatch, getState }
  ) => {
    const initialState = getState();
    const playingSongId = lookupOrUndefined(
      initialState.player.queueSongIds,
      playingSongIndex
    )?.[1];

    if (playingSongId) {
      await dispatch(checkPlayingSongDownloadIntegrity()); // the verification took less than 50ms in all cases I could test, so it is feasible to perform it.

      const authToken = initialState.tokens.auth;

      if (authToken) {
        const offlineSong = initialState.downloader.offlineSongs[playingSongId];

        dispatch(mountSongAction([{ playingSongIndex, repeatMode }, autoPlay]));
        const playingSong = playingSongSelector(getState());

        if (playingSong) {
          dispatch(
            mounted({
              authToken,
              autoPlay,
              offlineSong,
              playingSong,
              progress: playbackProgress,
            })
          );
        } else {
          throw new Error('impossible state, cannot find playing song');
        }
      }
    }
  }
);
