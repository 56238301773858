import { partition } from 'lodash';
import type { Workspace } from '../services/api/types/Workspace';

export function sortWorkspaces(workspaces: Workspace[]): Workspace[] {
  return partition(workspaces, (workspace) =>
    workspace.roleList.some((_) => _.roleId === 'owner')
  ).reduce(
    (acc, workspace) =>
      acc.concat(workspace.sort((a, b) => a.title.localeCompare(b.title))),
    []
  );
}
