/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import clsx from 'clsx';
import type { ComponentProps } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import VOLUME_TICK from '../../constants/player';
import styles from './SeekBar.module.scss';

const TOOLTIP_PADDING = 15;

function calc_tooltip_left_value(
  event: React.MouseEvent,
  containerRect: DOMRect
): [number, number] {
  if (containerRect) {
    const mouse_left = event.clientX - containerRect.left;
    const progress = normalizeToOne(mouse_left, containerRect.width);

    const clippedTooltipPosition = Math.min(
      Math.max(TOOLTIP_PADDING, mouse_left),
      containerRect.width - TOOLTIP_PADDING
    );

    return [clippedTooltipPosition, progress];
  }
  return [-1, 0];
}

interface Props {
  animate?: boolean;
  className?: string;
  disabled?: boolean;
  getTooltipValue?: (value: number, max: number) => string;
  max: number;
  onSeekComplete?: (value: number) => void;
  onSeeking?: (value: number) => void;
  progressBarProps?: ComponentProps<'div'>;
  secondaryProgressBarProps?: ComponentProps<'div'>;
  seekOnMouseWheel?: boolean;
  showTooltip?: boolean;
  stickyPrimaryBar?: boolean;
  value: number;
}

function normalizeToOne(value: number, max: number) {
  return Math.max(0, Math.min(1, value / max));
}

export default function SeekBar({
  animate = false,
  className,
  disabled = false,
  getTooltipValue,
  max,
  onSeekComplete,
  onSeeking,
  progressBarProps,
  secondaryProgressBarProps,
  seekOnMouseWheel = false,
  showTooltip = false,
  stickyPrimaryBar = false,
  value,
}: Props) {
  const container = useRef<HTMLDivElement>(null);
  const hitBoxRef = useRef<HTMLDivElement>(null);
  const [secondaryProgress, setSecondaryProgress] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState(0);
  const [tooltipValue, setTooltipValue] = useState(0);
  const [primaryProgress, setPrimaryProgress] = useState(
    normalizeToOne(value, max)
  );
  const mouseMoved = useRef(false);

  useEffect(() => {
    if (!mouseMoved.current) {
      setPrimaryProgress(normalizeToOne(value, max));
    }
  }, [value, max]);

  const onMouseMove = useCallback(
    (event: React.MouseEvent) => {
      const containerEl = container.current;
      if (!disabled && containerEl) {
        const containerRect = containerEl.getBoundingClientRect();

        if (showTooltip) {
          const [pos, prog] = calc_tooltip_left_value(event, containerRect);
          setTooltipPosition(pos);
          setTooltipValue(prog);
        } else {
          setTooltipPosition(-1);
        }

        setSecondaryProgress(
          normalizeToOne(
            event.clientX - containerRect.left,
            containerRect.width
          )
        );
      }
    },
    [disabled, showTooltip]
  );

  const onMouseDownHandler = (
    mouseDownEvent: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const containerRect = container.current?.getBoundingClientRect();
    function stickyMouse(event: MouseEvent) {
      if (containerRect) {
        const clippedProgress = normalizeToOne(
          event.clientX - containerRect.left,
          containerRect.width
        );

        setPrimaryProgress(() => {
          setTimeout(() => {
            mouseMoved.current = true;
            onSeeking?.(clippedProgress * max);
          });
          return clippedProgress;
        });
        setSecondaryProgress(0);
      }
    }

    if (!mouseMoved.current && !disabled) {
      if (stickyPrimaryBar) {
        stickyMouse(mouseDownEvent.nativeEvent);
        window.addEventListener('mousemove', stickyMouse);
      }

      window.addEventListener(
        'mouseup',
        () => {
          window.removeEventListener('mousemove', stickyMouse);
          setTooltipPosition(-1);

          if (stickyPrimaryBar && mouseMoved.current) {
            setPrimaryProgress((prev) => {
              setTimeout(() => {
                onSeekComplete?.(prev * max);
              });
              return prev;
            });
          }
          setTimeout(() => {
            mouseMoved.current = false; // small buffer to wait for other onSeekComplete reaction propagation to value
          }, 100);
        },
        {
          once: true,
        }
      );
    }
  };

  const onMouseLeave = () => {
    if (!mouseMoved.current) {
      setTooltipPosition(-1);
      setSecondaryProgress(0);
    }
  };

  const onMouseWheel = useCallback(
    (event: React.WheelEvent) => {
      if (seekOnMouseWheel) {
        if (event.deltaY > 0) {
          setPrimaryProgress((prev) => {
            const nextValue = Math.max(0, prev - VOLUME_TICK);
            setTimeout(() => {
              onSeekComplete?.(nextValue * max);
            });
            return nextValue;
          });
        } else {
          setPrimaryProgress((prev) => {
            const nextValue = Math.min(1, prev + VOLUME_TICK);
            setTimeout(() => {
              onSeekComplete?.(nextValue * max);
            });
            return nextValue;
          });
        }
      }
    },
    [max, onSeekComplete, seekOnMouseWheel]
  );

  const onClick = (e: React.MouseEvent) => {
    if (!disabled) {
      const containerEl = container.current;
      const hitBox = hitBoxRef.current?.getBoundingClientRect();
      if (containerEl && hitBox && hitBox.top <= e.clientY) {
        // Cursor position relative to container
        const rect = containerEl.getBoundingClientRect();
        const progress = Math.max(
          0,
          Math.min(1, (e.clientX - rect.left) / rect.width)
        );
        setPrimaryProgress(() => {
          setTimeout(() => {
            onSeekComplete?.(progress * max);
          });
          return progress;
        });
      }
    }
  };

  return (
    <div
      ref={container}
      className={clsx(styles.container, className)}
      data-tid="container"
      onClick={onClick}
      onMouseDown={onMouseDownHandler}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onWheel={onMouseWheel}
    >
      <div ref={hitBoxRef} className={styles.hitbox} />
      <div
        className={styles.tooltip}
        style={{
          left: `${tooltipPosition}px`,
          display: tooltipPosition > 0 && tooltipValue ? 'flex' : 'none',
        }}
      >
        <div className={styles.tooltipProgress}>
          {getTooltipValue ? getTooltipValue(tooltipValue, max) : ''}
        </div>
      </div>
      <div
        {...secondaryProgressBarProps}
        className={clsx(
          styles.secondaryProgress,
          secondaryProgressBarProps?.className
        )}
        style={{
          width: `${secondaryProgress * 100}%`,
          ...secondaryProgressBarProps?.style,
        }}
      />
      <div
        {...progressBarProps}
        className={clsx(
          styles.progress,
          animate && styles.anim,
          progressBarProps?.className
        )}
        data-testid="progress-bar"
        style={{
          width: `${disabled ? 0 : primaryProgress * 100}%`,
          ...progressBarProps?.style,
        }}
      />
    </div>
  );
}
