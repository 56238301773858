import type { StartQueryActionCreatorOptions } from '@reduxjs/toolkit/query';
import { flow } from 'fp-ts/lib/function';
import { none, some } from 'fp-ts/lib/Option';
import type {
  SafeParseReturnType,
  // ZodObject,
  // ZodRawShape,
  ZodType,
  ZodTypeDef,
} from 'zod';

export function toOption<Input, Output>(
  parseReturn: SafeParseReturnType<Input, Output>
) {
  return parseReturn.success ? some(parseReturn.data) : none;
}

export function safeParse<
  Output = unknown,
  Def extends ZodTypeDef = ZodTypeDef,
  Input = Output
>(zodType: ZodType<Output, Def, Input>) {
  return flow(zodType.safeParse, toOption);
}

export function defaultToNull<
  Output = unknown,
  Def extends ZodTypeDef = ZodTypeDef,
  Input = Output
>(schema: ZodType<Output, Def, Input>) {
  return schema.transform((value) => value ?? null);
}

// TODO: move to functions-utils

export function transformToNull<T>(value: T) {
  return value ?? null;
}

export function transformToFalse<T>(value: T) {
  return value ?? false;
}

export function transformToDate(value: string) {
  return new Date(value);
}

export function transformToZero<T>(value: T) {
  return value ?? 0;
}

export function transformTrim(value: string) {
  return value.trim();
}

export const emptyArray: unknown[] = [];
export const emptyObject: unknown = {};

export function transformEmptyArray<T>(value: T[] | undefined | null): T[] {
  return value ?? (emptyArray as T[]);
}

export function fromNullish<T, T1 extends T | null | undefined>(
  defaultValue: T
) {
  return (value: T1): T => {
    return value ?? defaultValue;
  };
}

// export function makeSchemaStrict<ObjSchema extends ZodObject<ZodRawShape>>(
//   objSchema: ObjSchema
// ) {
//   return process.env.NODE_ENV === 'production' ? objSchema : objSchema.strict();
// }

export const forceRefetchOptions: StartQueryActionCreatorOptions = {
  forceRefetch: true,
  subscribe: false,
};
