/* eslint-disable react/jsx-props-no-spreading */
import { throttle } from 'lodash';
import type { ComponentProps } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { match } from 'ts-pattern';
import { MaskIcon } from '../../theme/data-display/MaskIcon/MaskIcon';
import SeekBar from './SeekBar';
import seekBarStyles from './SeekBar.module.scss';
import styles from './VolumeControl.module.scss';

interface Props {
  maskIconProps?: Partial<ComponentProps<typeof MaskIcon>>;
  onToggleMute: () => void;
  onVolumeChange: (value: number) => void;
  seekBarProps?: Partial<ComponentProps<typeof SeekBar>>;
  volume: number;
}

export default function VolumeControl({
  maskIconProps,
  onToggleMute,
  onVolumeChange,
  seekBarProps,
  volume,
}: Props) {
  const [internalVolume, setInternalVolume] = useState(volume);

  useEffect(() => {
    setInternalVolume(volume);
  }, [volume]);

  const onSeeking = useMemo(() => {
    const throttled = throttle(onVolumeChange, 200, {
      leading: true,
      trailing: true,
    });

    return (value: number) => {
      setInternalVolume(value);
      throttled(value);
    };
  }, [onVolumeChange]);

  return (
    <div className={styles.container} data-tid="container">
      <button
        className={styles.volumeIcon}
        onClick={onToggleMute}
        title={match(internalVolume)
          .with(0, () => 'Unmute')
          .otherwise(() => 'Mute')}
        type="button"
      >
        <MaskIcon
          variant={match(internalVolume)
            .returnType<ComponentProps<typeof MaskIcon>['variant']>()
            .with(0, () => 'volumeMuted')
            .when(
              (v) => v < 0.5,
              () => 'volumeLow'
            )
            .otherwise(() => 'volumeHigh')}
          {...maskIconProps}
        />
      </button>
      <div className={styles.volumeContainer} data-testid="volume-bar">
        <SeekBar
          animate
          className={seekBarStyles.volume}
          max={1}
          onSeekComplete={onVolumeChange}
          onSeeking={onSeeking}
          seekOnMouseWheel
          stickyPrimaryBar
          value={internalVolume}
          {...seekBarProps}
        />
      </div>
    </div>
  );
}
