import { z } from 'zod';
import type { UserId } from '../../../../common/Opaques';
import {
  WorkspaceIdSchema,
  WorkspaceInvitationIdSchema,
} from '../../../../common/Opaques';
import { CollaboratorSchema } from '../../../types/Collaborator';

// TODO: change properties snake_case to camelCase
export const WorkspaceRoleSchema = z.union([
  z.literal('viewer'),
  z.literal('owner'),
  z.literal('editor'),
  z.literal('guest'),
  z.literal('admin'),
]);

export type WorkspaceRole = z.infer<typeof WorkspaceRoleSchema>;

export const WorkspaceMemberSchema = z
  .object({
    member_since: z.string(),
    user: CollaboratorSchema,
    workspace_id: WorkspaceIdSchema,
    workspace_role: WorkspaceRoleSchema,
  })
  .transform((prev) => ({
    memberSince: prev.member_since,
    user: prev.user,
    workspaceId: prev.workspace_id,
    workspaceRole: prev.workspace_role,
  }));

export type WorkspaceMember = z.infer<typeof WorkspaceMemberSchema>;

export const WorkspaceInvitationSchema = z
  .object({
    created_at: z.string().transform((date) => new Date(date)),
    workspace_id: WorkspaceIdSchema,
    invitation_id: WorkspaceInvitationIdSchema,
    role: WorkspaceRoleSchema,
    email: z.string(),
  })
  .transform((prev) => ({
    createdAt: prev.created_at,
    workspaceId: prev.workspace_id,
    invitationId: prev.invitation_id,
    role: prev.role,
    email: prev.email,
  }));

export type WorkspaceInvitation = z.infer<typeof WorkspaceInvitationSchema>;

export const defaultWorkspaceMember: WorkspaceMember = {
  workspaceId: WorkspaceIdSchema.parse('s_vxwaEOn'),
  memberSince: '2024-01-13T14:11:40.000Z',
  workspaceRole: 'editor',
  user: {
    artistName: 'artistName',
    // artworkUrl: 'https://i.pravatar.cc/150?img=1',
    avatarUrl: 'https://i.pravatar.cc/150?img=1',
    createdAt: new Date('2024-01-13T14:11:40.000Z'),
    email: 'email',
    location: 'location',
    name: 'name',
    occupation: 'occupation',
    userId: 'userId' as UserId,
  },
};
