import type { ObjectToUnion, Simplify } from './types-utils';

export function difference<
  T0 extends Record<string, unknown>,
  T1 extends Record<string, unknown>
>(a: T0, b: T1): Simplify<ObjectToUnion<T1>> {
  return Object.fromEntries(
    Object.entries(b).filter(([key, value]) => value !== a[key])
  ) as any;
}

export function isNullish<T>(
  value: null | undefined | T
): value is null | undefined {
  return value === null || value === undefined;
}
