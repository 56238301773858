import { createAction } from '@reduxjs/toolkit';
// import * as Sentry from '@sentry/browser';
import { match } from 'fp-ts/lib/Option';
import { not } from 'fp-ts/lib/Predicate';
import { constFalse, pipe } from 'fp-ts/lib/function';
import { replace } from 'redux-first-history';
import type {
  ProjectId,
  ProjectInvitationId,
  WorkspaceId,
  WorkspaceInvitationId,
} from '../../common/Opaques';
import { createAppAsyncThunk } from '../reducers/types';
import { api } from '../services/api/api';
import type {
  UpdateWorkspaceDescriptionFormField,
  UpdateWorkspaceDetailsFormField,
} from '../services/api/types/UpdateWorkspaceForm';
import type { Workspace } from '../services/api/types/Workspace';
import type { WorkspaceRole } from '../services/api/types/WorkspaceMember';
import type { PlaylistGuestRole } from '../types/Role';
import type { WorkspaceProjectRole } from '../types/WorkspaceProjectRole';
import { reportEverywhere, tryCatchFinallyReport } from '../utils/error-utils';
import type { HomePath } from '../utils/route-utils';
import {
  isWorkspacePath,
  matchPossiblePaths,
  mkAllFilesPath,
} from '../utils/route-utils';
import { enqueueSnackbar, setModal } from './view';
import { Action } from './workspace/Action';

export const debugToggleFetchWorkspaces = createAction<
  void,
  Action.DEBUG_TOGGLE_FETCH_WORKSPACES
>(Action.DEBUG_TOGGLE_FETCH_WORKSPACES);

export const workspaceChanged = createAction<
  WorkspaceId,
  Action.WORKSPACE_CHANGED
>(Action.WORKSPACE_CHANGED);

export const changeWorkspace = createAppAsyncThunk<
  void,
  WorkspaceId | undefined
>(Action.CHANGE_WORKSPACE, async (workspaceId, { dispatch, getState }) => {
  return tryCatchFinallyReport('ERROR_CHANGE_WORKSPACE', async () => {
    const {
      workspace: { selectedWorkspaceId },
    } = getState();

    if (!workspaceId) {
      throw new Error('No workspaceId');
    }

    if (selectedWorkspaceId !== workspaceId) {
      // const workspaceDB = await mkWorkspaceDatabase(workspaceId);

      // const projects = (await getProjects(workspaceDB)).sort((a, b) =>
      //   a.title.localeCompare(b.title)
      // );

      dispatch(workspaceChanged(workspaceId));

      // const [folders, playlists, songDocs] = await Promise.all([
      //   getWorkspaceFolders(workspaceDB),
      //   getWorkspacePlaylists(workspaceDB),
      //   getSongs(workspaceDB),
      // ]);

      // dispatch(receiveFolders(folders));
      // dispatch(receivePlaylists(playlists));
      // dispatch(receiveSongs(songDocs, true));

      // if (workspaceId && auth) {
      //   const offlineSongs = await getAllCache(songDocs, auth);
      //   dispatch(addOfflineSongs(offlineSongs));
      // }
    }
  });
});

export const navigateWorkspace = createAppAsyncThunk<void, HomePath>(
  Action.NAVIGATE_WORKSPACE,
  async (homePath, { dispatch, getState }) => {
    return tryCatchFinallyReport('ERROR_NAVIGATE_WORKSPACE', async () => {
      switch (homePath.type) {
        case 'workspace':
          {
            await dispatch(changeWorkspace(homePath.match.params.workspaceId));
            const prevPath =
              getState().workspace.scoped[homePath.match.params.workspaceId]
                ?.lastRoute;

            if (
              prevPath &&
              pipe(
                matchPossiblePaths(prevPath),
                match(constFalse, not(isWorkspacePath))
              )
            ) {
              dispatch(replace(prevPath.pathname + prevPath.search));
            } else {
              dispatch(
                replace(mkAllFilesPath(homePath.match.params.workspaceId))
              );
            }
          }

          break;
        // case 'library': {
        //   await dispatch(changeWorkspace(homePath.match.params.workspaceId));

        //   // TODO: add to list view
        //   // const audioId = getState().router.location.query.audio_id as SongId;

        //   // if (audioId) {
        //   //   dispatch(selectOnlyOneSong(audioId));
        //   // }

        //   break;
        // }
        case 'folder':
          await dispatch(changeWorkspace(homePath.match.params.workspaceId));
          break;
        case 'all files':
        case 'starred': {
          await dispatch(changeWorkspace(homePath.match.params.workspaceId));
          break;
        }
        default:
          break;
      }
    });
  }
);

export const tryInviteProjectMembers = createAppAsyncThunk<
  void,
  [ProjectId, string[], WorkspaceProjectRole]
>(
  Action.CREATE_PROJECT,
  async ([projectId, emails, role], { dispatch, getState }) => {
    try {
      const state = getState();
      const { selectedWorkspaceId } = state.workspace;
      const { auth } = state.tokens;

      if (auth && selectedWorkspaceId) {
        await dispatch(
          api.endpoints.addUserToProject.initiate({
            workspaceId: selectedWorkspaceId,
            projectId,
            data: {
              emails,
              role,
            },
          })
        );
      }
      dispatch(setModal({ type: 'none' }));
    } catch (e) {
      // Sentry.captureException(e);
      // log.error(e);
      console.error(e);
      throw e;
    }
  }
);

export const tryInvitePlaylistGuests = createAppAsyncThunk<
  void,
  [ProjectId, string, string[], PlaylistGuestRole]
>(
  Action.CREATE_PROJECT,
  async ([projectId, playlistId, emails, role], { dispatch, getState }) => {
    try {
      const state = getState();
      const { selectedWorkspaceId } = state.workspace;
      const { auth } = state.tokens;

      if (auth && selectedWorkspaceId) {
        await dispatch(
          api.endpoints.addUserToProject.initiate({
            workspaceId: selectedWorkspaceId,
            projectId,
            data: {
              emails,
              role: 'project_guest',
              playlist_role: role,
              playlist_id: playlistId,
            },
          })
        );
      }
      dispatch(setModal({ type: 'none' }));
    } catch (e) {
      // Sentry.captureException(e);
      // log.error(e);
      console.error(e);
      throw e;
    }
  }
);

export const unsafeRemoveMemberFromProject = createAppAsyncThunk<
  void,
  [ProjectId, string]
>(
  Action.REMOVE_MEMBER_FROM_PROJECT,
  async ([projectId, userId], { dispatch, getState }) => {
    try {
      const state = getState();
      const { selectedWorkspaceId } = state.workspace;

      if (selectedWorkspaceId) {
        await dispatch(
          api.endpoints.removeMemberFromProject.initiate({
            workspaceId: selectedWorkspaceId,
            projectId,
            userId,
          })
        );
      }
    } catch (e) {
      reportEverywhere(Action.CREATE_PROJECT)(e);
      throw e;
    }
  }
);

export const tryRemoveInvitationToProject = createAppAsyncThunk<
  void,
  [ProjectId, ProjectInvitationId]
>(
  Action.REMOVE_INVITATION_TO_PROJECT,
  async ([projectId, invitationId], { dispatch, getState }) => {
    try {
      const state = getState();
      const { selectedWorkspaceId } = state.workspace;
      const { auth } = state.tokens;

      if (auth && selectedWorkspaceId) {
        await dispatch(
          api.endpoints.removeInvitationToProject.initiate({
            workspaceId: selectedWorkspaceId,
            projectId,
            invitationId,
          })
        );
      }
    } catch (e) {
      reportEverywhere(Action.REMOVE_INVITATION_TO_PROJECT)(e);
      throw e;
    }
  }
);

export const tryReinviteInvitationToProject = createAppAsyncThunk<
  void,
  [ProjectId, string]
>(
  Action.REINVITE_TO_PROJECT,
  async ([projectId, email], { dispatch, getState }) => {
    try {
      const state = getState();
      const { selectedWorkspaceId } = state.workspace;
      const { auth } = state.tokens;
      if (auth && selectedWorkspaceId) {
        await dispatch(
          api.endpoints.reinviteInvitationToProject.initiate({
            workspaceId: selectedWorkspaceId,
            projectId,
            email,
          })
        );

        dispatch(enqueueSnackbar('Invitation sent'));
      }
    } catch (e) {
      reportEverywhere(Action.REINVITE_TO_PROJECT)(e);
      throw e;
    }
  }
);

export const editWorkspace = createAppAsyncThunk<
  void,
  {
    workspace: Workspace;
    update?:
      | UpdateWorkspaceDetailsFormField
      | UpdateWorkspaceDescriptionFormField;
    artwork?: File | undefined;
  }
>(
  Action.EDIT_WORKSPACE,
  async ({ workspace, update, artwork }, { dispatch }) => {
    try {
      await dispatch(
        api.endpoints.updateWorkspace.initiate({
          workspace,
          update,
          artwork,
        })
      );
      dispatch(setModal({ type: 'none' }));
    } catch (e) {
      reportEverywhere(Action.EDIT_WORKSPACE)(e);
      throw e;
    }
  }
);

export const tryUpdateWorkspaceMemberRole = createAppAsyncThunk<
  void,
  [string, WorkspaceRole]
>(
  Action.UPDATE_WORKSPACE_MEMBER_ROLE,
  async ([userId, role], { dispatch, getState }) => {
    try {
      const state = getState();
      const { selectedWorkspaceId } = state.workspace;

      if (selectedWorkspaceId) {
        await dispatch(
          api.endpoints.changeWorkspaceMemberRole.initiate({
            workspaceId: selectedWorkspaceId,
            userId,
            role,
          })
        );
      }
    } catch (e) {
      reportEverywhere(Action.UPDATE_WORKSPACE_MEMBER_ROLE)(e);
      throw e;
    }
  }
);

export const tryRemoveMemberFromWorkspace = createAppAsyncThunk<void, string>(
  Action.REMOVE_MEMBER_FROM_WORKSPACE,
  async (userId, { dispatch, getState }) => {
    try {
      const state = getState();
      const { selectedWorkspaceId } = state.workspace;

      if (selectedWorkspaceId) {
        await dispatch(
          api.endpoints.deleteWorkspaceMember.initiate(
            {
              workspaceId: selectedWorkspaceId,
              userId,
            },
            {
              fixedCacheKey: 'deleteWorkspaceMember',
            }
          )
        );
      }
    } catch (e) {
      reportEverywhere(Action.REMOVE_MEMBER_FROM_WORKSPACE)(e);
      throw e;
    }
  }
);

export const tryInviteMemberToWorkspace = createAppAsyncThunk<
  void,
  [string[], WorkspaceRole]
>(
  Action.INVITE_MEMBER_TO_WORKSPACE,
  async ([emails, role], { getState, dispatch }) => {
    try {
      const state = getState();
      const { selectedWorkspaceId } = state.workspace;

      if (selectedWorkspaceId) {
        await dispatch(
          api.endpoints.inviteMemberToAWorkspace.initiate({
            workspaceId: selectedWorkspaceId,
            emails,
            role,
          })
        );

        dispatch(setModal({ type: 'none' }));
      }
    } catch (e) {
      reportEverywhere(Action.INVITE_MEMBER_TO_WORKSPACE)(e);
      throw e;
    }
  }
);

export const tryUpdateWorkspaceInvitationRole = createAppAsyncThunk<
  void,
  [WorkspaceInvitationId, WorkspaceRole]
>(
  Action.UPDATE_WORKSPACE_INVITATION_ROLE,
  async ([invitationId, role], { getState, dispatch }) => {
    try {
      const state = getState();
      const { selectedWorkspaceId } = state.workspace;

      if (selectedWorkspaceId) {
        await dispatch(
          api.endpoints.changeWorkspaceInvitation.initiate({
            workspaceId: selectedWorkspaceId,
            invitationId,
            role,
          })
        );
      }
    } catch (e) {
      reportEverywhere(Action.UPDATE_WORKSPACE_INVITATION_ROLE)(e);
      throw e;
    }
  }
);

export const tryRemoveWorkspaceInvitation = createAppAsyncThunk<
  void,
  WorkspaceInvitationId
>(
  Action.REMOVE_WORKSPACE_INVITATION,
  async (invitationId, { dispatch, getState }) => {
    try {
      const state = getState();
      const { selectedWorkspaceId } = state.workspace;

      if (selectedWorkspaceId) {
        await dispatch(
          api.endpoints.removeWorkspaceInvitation.initiate({
            workspaceId: selectedWorkspaceId,
            invitationId,
          })
        );
      }
    } catch (e) {
      reportEverywhere(Action.REMOVE_WORKSPACE_INVITATION)(e);
      throw e;
    }
  }
);
