export enum ErrorCodes {
  ERROR_CHANGE_PROJECT = 'ERROR_CHANGE_PROJECT',
  ERROR_CHANGE_WORKSPACE = 'ERROR_CHANGE_WORKSPACE',
  ERROR_EDIT_PROJECT = 'ERROR_EDIT_PROJECT',
  ERROR_FETCHING_ACTIVITIES = 'ERROR_FETCHING_ACTIVITIES',
  ERROR_FETCHING_FOLDER = 'ERROR_FETCHING_FOLDER',
  ERROR_FETCHING_FOLDERS = 'ERROR_FETCHING_FOLDERS',
  ERROR_FETCHING_NOTIFICATION_SETTINGS = 'ERROR_FETCHING_NOTIFICATION_SETTINGS',
  ERROR_FETCHING_UNREAD_ACTIVITIES = 'ERROR_FETCHING_UNREAD_ACTIVITIES',
  ERROR_FETCH_FOLDERS = 'ERROR_FETCH_FOLDERS',
  ERROR_FETCH_PROJECT_PLAYLISTS = 'ERROR_FETCH_PROJECT_PLAYLISTS',
  ERROR_FETCH_LIBRARY_PLAYLISTS = 'ERROR_FETCH_LIBRARY_PLAYLISTS',
  ERROR_FETCH_PROJECTS = 'ERROR_FETCH_PROJECTS',
  ERROR_FETCH_PROJECT_MEMBERS = 'ERROR_FETCH_PROJECT_MEMBERS',
  ERROR_FETCH_SONGS = 'ERROR_FETCH_SONGS',
  ERROR_FETCH_WORKSPACES = 'ERROR_FETCH_WORKSPACES',
  ERROR_FETCH_WORKSPACE_INVITATIONS = 'ERROR_FETCH_WORKSPACE_INVITATIONS',
  ERROR_FETCH_WORKSPACE_MEMBERS = 'ERROR_FETCH_WORKSPACE_MEMBERS',
  ERROR_FETCH_WORKSPACE_SUBSCRIPTION = 'ERROR_FETCH_WORKSPACE_SUBSCRIPTION',
  ERROR_MIGRATING_REDUX = 'ERROR_MIGRATING_REDUX',
  ERROR_NAVIGATE_WORKSPACE = 'ERROR_NAVIGATE_WORKSPACE',
  ERROR_PLAYING_FROM_VISIBLE_SONGS = 'ERROR_PLAYING_FROM_VISIBLE_SONGS',
  ERROR_RENAME_FOLDER = 'ERROR_RENAME_FOLDER',
  ERROR_SELECT_ALL_FILES = 'ERROR_SELECT_ALL_FILES',
  ERROR_SELECT_STARRED = 'ERROR_SELECT_STARRED',
  ERROR_TRY_CREATE_PROJECT = 'ERROR_TRY_CREATE_PROJECT',
  ERROR_UPDATE_AND_SHARE_PLAYLIST = 'ERROR_UPDATE_AND_SHARE_PLAYLIST',
  ERROR_WRITING_REDUX_STATE = 'ERROR_WRITING_REDUX_STATE',
  ERROR_FETCH_WORKSPACE_FOLDERS = 'ERROR_FETCH_WORKSPACE_FOLDERS',
  ERROR_IN_USE_POUCH_TO_REDUX = 'ERROR_IN_USE_POUCH_TO_REDUX',
  ERROR_DECLINING_INVITATION_IN_ACTIVITY_PANE = 'ERROR_DECLINING_INVITATION_IN_ACTIVITY_PANE',
  ERROR_ACCEPTING_INVITATION_IN_ACTIVITY_PANE = 'ERROR_ACCEPTING_INVITATION_IN_ACTIVITY_PANE',
  EMPTY_WORKSPACE_ID = 'EMPTY_WORKSPACE_ID',
}
