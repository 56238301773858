import { z } from 'zod';
import type { UserId } from '../../common/Opaques';
import {
  CommentIdSchema,
  SongIdSchema,
  UserIdSchema,
} from '../../common/Opaques';
import { transformToDate } from '../../common/zod-utils';

export const ControlCommentSchema = z.object({
  comment_id: CommentIdSchema,
  user_id: UserIdSchema,
  audio_id: SongIdSchema,
  text: z.string(),
  timestamp: z.number().nullish(),
  created_at: z.string().transform(transformToDate),
  deleted_at: z.string().transform(transformToDate).nullish(),
  name: z.string(),
  avatar_url: z.string(),
  mentions: z.unknown(),
});

export type ControlComment = z.infer<typeof ControlCommentSchema>;

export const GetControlCommentListResponseSchema = z.object({
  records: ControlCommentSchema.array(),
  size: z.number(),
});

export type GetControlCommentListResponse = z.infer<
  typeof GetControlCommentListResponseSchema
>;

export const defaultControlComment: ControlComment = {
  audio_id: SongIdSchema.parse('1'),
  avatar_url: 'https://example.com',
  comment_id: CommentIdSchema.parse('1'),
  created_at: new Date('2021-01-01T00:00:00Z'),
  mentions: [],
  name: 'John Doe',
  text: 'Hello World',
  timestamp: null,
  user_id: '1' as UserId,
};
