import type { UniqueIdentifier } from '@dnd-kit/core';
import { constant, constFalse, pipe } from 'fp-ts/lib/function';
import type { Option } from 'fp-ts/Option';
import { fold, isNone, none, some } from 'fp-ts/Option';
import { union } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import type { FolderId, ProjectId, ResourceId } from '../../common/Opaques';
import type { Project } from '../services/api/types/Workspace';
import type { Folder } from '../types/Folder';
import type { Playlist } from '../types/Playlist';

function allParents(
  folders: Folder[],
  folderId: FolderId | null,
  projectId: ProjectId | null
): string[] {
  const path: string[] = projectId ? [projectId] : [];
  let nextId: FolderId | null = folderId;

  while (nextId) {
    // eslint-disable-next-line no-loop-func
    const folder = folders.find((f) => f.folderId === nextId);
    if (folder) {
      path.push(folder.folderId);
      nextId = folder.parentFolderId;
    } else {
      nextId = null;
    }
  }

  return path;
}

export function allChildrenFolders(
  folders: Folder[],
  parent: string
): FolderId[] {
  return folders
    .filter(
      (f) =>
        (f.parentFolderId && f.parentFolderId === parent) ||
        (f.projectId && f.projectId === parent)
    )
    .flatMap((f) => [f.folderId, ...allChildrenFolders(folders, f.folderId)]);
}

function manuallyExpand(
  shift: boolean,
  alt: boolean,
  nodeId: string,
  projects: Project[],
  folders: Folder[],
  prev: string[]
): string[] {
  if (shift && alt) {
    if (prev.length) {
      return [];
    }
    return folders
      .map((folder) => folder.folderId as string)
      .concat(projects.map((project) => project.stringId as string));
  }
  if (alt) {
    const children = allChildrenFolders(folders, nodeId);
    if (prev.indexOf(nodeId) === -1) {
      return [...prev, nodeId, ...children];
    }
    return prev.filter(
      (v) => v !== nodeId && !(children as string[]).includes(v)
    );
  }
  return prev.indexOf(nodeId) === -1
    ? [...prev, nodeId]
    : prev.filter((v) => v !== nodeId);
}

export function useTreeExpansion(
  startExpanded: boolean,
  folders: Folder[],
  projects: Project[],
  treeExpanded: string[],
  onSyncExpanded: (args: string[]) => void,
  selectedPlaylist?: Playlist,
  selectedProject?: Project
) {
  const [expanded, setExpanded] = useState<string[]>(treeExpanded);

  useEffect(() => {
    if (startExpanded) {
      // start expanded for new users: https://trello.com/c/UtNwNO7z/
      setExpanded(
        folders
          .map((folder) => folder.folderId as string)
          .concat(projects.map((project) => project.stringId as string))
      );
    }
  }, [folders, projects, startExpanded]);

  useEffect(() => {
    // open all folders where a playlist is at https://trello.com/c/bIWi5DTv
    if (selectedPlaylist) {
      const path = allParents(
        folders,
        selectedPlaylist.folderId,
        selectedPlaylist.projectId
      );

      setExpanded((prev) => {
        const next = union(prev, path);
        onSyncExpanded(next);
        return next;
      });
    }
  }, [
    folders,
    selectedPlaylist,
    selectedPlaylist?.folderId,
    selectedProject?.stringId,
    setExpanded,
    onSyncExpanded,
  ]);

  return useMemo(
    () => ({
      expanded,
      toggleExpand: (shift: boolean, alt: boolean, nodeId: string) => {
        setExpanded((prev) => {
          const next = manuallyExpand(
            shift,
            alt,
            nodeId,
            projects,
            folders,
            prev
          );
          onSyncExpanded(next);
          return next;
        });
      },
    }),
    [expanded, folders, projects, onSyncExpanded]
  );
}

export type Leaf = Project | Folder | Playlist;

export type Branch<T0 = Leaf, T1 extends T0 = T0> = {
  children: Tree<T0>;
  collapsed?: boolean;
  depth: number;
  id: UniqueIdentifier;
  leaf: T1;
  path: ResourceId[];
  pinned?: boolean;
};

export type Tree<T = Leaf> = Branch<T>[];

export interface FlattenedItem<T = Leaf> extends Branch<T> {
  parentId: UniqueIdentifier | null;
}

function flatten<T>(
  items: Tree<T>,
  parentId: UniqueIdentifier | null = null,
  depth = 0
): FlattenedItem<T>[] {
  return items.reduce<FlattenedItem<T>[]>((acc, item, index) => {
    return [
      ...acc,
      { ...item, parentId, depth, index },
      ...flatten(item.children, item.id, depth + 1),
    ];
  }, []);
}

export function flattenTree<T>(items: Tree<T>): FlattenedItem<T>[] {
  return flatten(items);
}

export function removeChildrenOf<T>(
  items: FlattenedItem<T>[],
  ids: UniqueIdentifier[]
) {
  const excludeParentIds = [...ids];

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) {
        excludeParentIds.push(item.id);
      }
      return false;
    }

    return true;
  });
}

export function getLibraryTree(
  currentFolder: Option<Folder>,
  playlistsInFolders: string[],
  folders: Folder[],
  playlists: Playlist[],
  projects: Project[],
  currentProject: Option<Project>,
  depth: number,
  path: ResourceId[]
): Tree<Leaf> {
  const projectItems: Tree<Leaf> = pipe(
    currentProject,
    fold(constant(isNone(currentFolder)), constFalse)
  )
    ? projects
        .toSorted((a, b) => a.title.localeCompare(b.title))
        .map((project) => ({
          leaf: project,
          children: getLibraryTree(
            none,
            playlistsInFolders,
            folders,
            playlists,
            projects,
            some(project),
            depth + 1,
            path.concat(project.resourceId)
          ),
          depth,
          id: project.stringId,
          path,
        }))
    : [];

  const folderItems: Tree<Leaf> = folders
    .filter(
      (folder) =>
        pipe(
          currentProject,
          fold(
            constant(folder.projectId === null),
            (_) => folder.projectId === _.stringId
          )
        ) &&
        pipe(
          currentFolder,
          fold(
            constant(folder.parentFolderId === null),
            (_) => folder.parentFolderId === _.folderId
          )
        )
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((folder) => ({
      leaf: folder,
      children: getLibraryTree(
        some(folder),
        playlistsInFolders,
        folders,
        playlists,
        projects,
        currentProject,
        depth + 1,
        path.concat(folder.resourceId)
      ),
      depth,
      id: folder.folderId,
      path,
    }));

  const playlistItems: Tree<Leaf> = playlists
    .filter(
      (playlist) =>
        pipe(
          currentProject,
          fold(
            constant(playlist.projectId === null),
            ({ stringId }) => playlist.projectId === stringId
          )
        ) &&
        pipe(
          currentFolder,
          fold(
            constant(!playlistsInFolders.includes(playlist.playlistId)),
            ({ folderId }) => playlist.folderId === folderId
          )
        )
    )
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((playlist) => ({
      leaf: playlist,
      children: [],
      depth,
      id: playlist.playlistId,
      path,
    }));

  return projectItems.concat(folderItems).concat(playlistItems);
}

export interface Projected {
  parentId: UniqueIdentifier | null;
}

export function getProjection(overId: UniqueIdentifier): Projected {
  return {
    parentId: overId ?? null,
  };
}
