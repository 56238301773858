/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import clsx from 'clsx';
import type { ComponentProps } from 'react';
import React, { useState } from 'react';
import { match } from 'ts-pattern';
import albumPlay from '../../../assets/images/album_play.svg';
import artworkPlaceholder from '../../../assets/images/default_artwork.svg';
import playlistPlaceholder from '../../../assets/images/playlist_placeholder2.svg';
import { Squircle } from '../theme/data-display/Squircle/Squircle';
import { getColorById } from '../utils/color-utils';
import styles from './Artwork.module.css';

export interface DefaultArtworkProps {
  variant?: 'default';
  backgroundColor?: string;
  borderBottomRadius?: number;
  borderRadius?: number;
  borderTopRadius?: number;
  coverImagePath?: string | null;
  id?: string;
  onClick?: () => void;
  onContextMenu?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onPlayButtonClick?: (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => void;
  placeholder?: 'file' | 'playlist';
  size: number; // Size is required for the image to be center cropped correctly
  skeleton?: boolean;
  squircle?: boolean;
  title?: string;
}

export type ArtworkProps =
  | DefaultArtworkProps
  | {
      placeholder?: 'file' | 'playlist';
      variant: 'maximized';
      imgProps?: ComponentProps<'img'>;
      backgroundColor?: string;
      coverImagePath?: string | null;
      id?: string;
      title?: string;
    };

// https://github.com/Tourean/control-macos-ios/blob/fa46caa5fcf7b23c27e09b2e0600e509db19dcb2/Shared/CConstants.h#L196
const defaultBorderRadius = 6;

export default function Artwork(props: ArtworkProps) {
  const [showBtn, setShowBtn] = useState(false);

  return match(props)
    .with(
      { variant: 'maximized' },
      ({
        title,
        coverImagePath,
        placeholder = 'file',
        id,
        backgroundColor,
        imgProps,
      }) => {
        const color =
          placeholder === 'file' ? `#${getColorById(id ?? '')}` : undefined;

        return (
          <img
            title={title}
            {...imgProps}
            className={clsx(
              'rounded-md h-full object-contain aspect-square',
              // NOTE: this switches so portrait works, but the app should never render portrait as major decision, so this might not be necessary:

              // 'rounded-md max-w-full h-auto object-contain aspect-square portrait:max-w-none portrait:w-full portrait:h-fit',
              imgProps?.className
            )}
            src={coverImagePath ?? artworkPlaceholder}
            style={{
              background: backgroundColor || color,
              ...imgProps?.style,
            }}
          />
        );
      }
    )
    .otherwise(
      ({
        backgroundColor = undefined,
        borderBottomRadius,
        borderRadius = defaultBorderRadius,
        borderTopRadius,
        coverImagePath,
        id,
        onClick,
        onContextMenu,
        onPlayButtonClick,
        placeholder = 'file',
        size,
        skeleton,
        squircle = false,
        title,
      }) => {
        const color =
          placeholder === 'file' ? `#${getColorById(id ?? '')}` : undefined;
        return (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className={clsx(
              styles.container,
              size === 0 && styles.cropped,
              squircle && styles.squircle
            )}
            onClick={onClick}
            onContextMenu={onContextMenu}
            onMouseEnter={() => setShowBtn(true)}
            onMouseLeave={() => setShowBtn(false)}
            style={{
              width: size ? `${size}px` : '0',
              height: size ? `${size}px` : '0',
              flex: size ? `0 0 ${size}px` : undefined,
            }}
            title={title}
          >
            {squircle ? (
              <Squircle
                char={title?.charAt(0) ?? ''}
                id={id!}
                imageUrl={coverImagePath}
                size="X"
              />
            ) : skeleton ? (
              <div
                className={styles.skeletonImage}
                style={{
                  position: size ? 'relative' : 'absolute',
                  left: size ? 'auto' : 0,
                  top: size ? 'auto' : 0,
                  width: size ? `${size}px` : '100%',
                  height: size ? `${size}px` : '100%',
                }}
              />
            ) : (
              <img
                className={styles.image}
                src={
                  coverImagePath ??
                  (placeholder === 'file'
                    ? artworkPlaceholder
                    : playlistPlaceholder)
                }
                style={{
                  position: size ? 'relative' : 'absolute',
                  left: size ? 'auto' : 0,
                  top: size ? 'auto' : 0,
                  background: backgroundColor || color,
                  width: size ? `${size}px` : '100%',
                  height: size ? `${size}px` : '100%',
                  borderBottomLeftRadius: `${
                    borderBottomRadius || borderRadius || 0
                  }px`,
                  borderBottomRightRadius: `${
                    borderBottomRadius || borderRadius || 0
                  }px`,
                  borderTopLeftRadius: `${
                    borderTopRadius || borderRadius || 0
                  }px`,
                  borderTopRightRadius: `${
                    borderTopRadius || borderRadius || 0
                  }px`,
                }}
              />
            )}
            {onPlayButtonClick && showBtn && (
              <div className={styles.playbtn}>
                <img
                  height={size / 1.8}
                  onClick={(e) => {
                    onPlayButtonClick(e);
                  }}
                  src={albumPlay}
                  width={size / 1.8}
                />
              </div>
            )}
          </div>
        );
      }
    );
}
