import { createAction } from '@reduxjs/toolkit';
import type { Option } from 'fp-ts/lib/Option';
import { push } from 'redux-first-history';
import type { SongId } from '../../../common/Opaques';
import type { Song } from '../../../common/Song';
import type {
  PlayerQueueItemInput,
  PlayerStateType,
} from '../../reducers/types';
import { createAppAsyncThunk } from '../../reducers/types';
import { constPayload } from '../../utils/function-utils';
import type { HomePath } from '../../utils/route-utils';
import { mkAllFilesPath } from '../../utils/route-utils';

export enum Action {
  ADD_PLAYING_SONG_TO_QUEUE = 'PLAYER/ADD_PLAYING_SONG_TO_QUEUE',
  ADD_SONGS_TO_QUEUE = 'PLAYER/ADD_SONGS_TO_QUEUE',
  CHANGE_REPEAT_MODE = 'PLAYER/CHANGE_REPEAT_MODE',
  COPY_TO_QUEUE = 'PLAYER/COPY_TO_QUEUE',
  FORWARD = 'PLAYER/FORWARD',
  MOUNT_SONG = 'PLAYER/MOUNT_SONG',
  MOVE_QUEUE_SONG = 'PLAYER/MOVE_QUEUE_SONG',
  PAUSE = 'PLAYER/PAUSE',
  PLAY = 'PLAYER/PLAY',
  PLAY_FROM_QUEUE = 'PLAYER/PLAY_FROM_QUEUE',
  PLAY_NEXT_SONG = 'PLAYER/PLAY_NEXT_SONG',
  PLAY_PREVIOUS_SONG = 'PLAYER/PLAY_PREVIOUS_SONG',
  PLAY_SELECTED_SONG = 'PLAYER/PLAY_SELECTED_SONG',
  SEEK = 'PLAYER/SEEK',
  SELECT_SONG_IN_LIST = 'PLAYER/SELECT_SONG_IN_LIST',
  SET_QUEUE_SONGS = 'PLAYER/SET_QUEUE_SONGS',
  SET_VOLUME = 'PLAYER/SET_VOLUME',
  SONG_ENDED = 'PLAYER/SONG_ENDED',
  TOGGLE_MUTE = 'PLAYER/TOGGLE_MUTE',
  TOGGLE_PLAYBACK = 'PLAYER/TOGGLE_PLAYBACK',
  TOGGLE_SHUFFLE = 'PLAYER/TOGGLE_SHUFFLE',
  UNMOUNT_SONG = 'PLAYER/UNMOUNT_SONG',
  UNSAFE_SET_PLAYBACK_STATE = 'PLAYER/UNSAFE_SET_PLAYBACK_STATE',
  UPDATE_PLAYBACK_PROGRESS = 'PLAYER/UPDATE_PLAYBACK_PROGRESS',
}

export const unsafePlaybackState = createAction<
  boolean,
  Action.UNSAFE_SET_PLAYBACK_STATE
>(Action.UNSAFE_SET_PLAYBACK_STATE);

export const playSelectedSongAction = createAction<
  [HomePath, SongId[], number],
  Action.PLAY_SELECTED_SONG
>(Action.PLAY_SELECTED_SONG);

export const togglePlaybackAction = createAction<void, Action.TOGGLE_PLAYBACK>(
  Action.TOGGLE_PLAYBACK
);

export const songEndedAction = createAction<void, Action.SONG_ENDED>(
  Action.SONG_ENDED
);

export const playPreviousSongAction = createAction<
  void,
  Action.PLAY_PREVIOUS_SONG
>(Action.PLAY_PREVIOUS_SONG);

export const playNextSongAction = createAction<void, Action.PLAY_NEXT_SONG>(
  Action.PLAY_NEXT_SONG
);

export const mountSongAction = createAction<
  [Pick<PlayerStateType, 'playingSongIndex' | 'repeatMode'>, boolean],
  Action.MOUNT_SONG
>(Action.MOUNT_SONG);

export const unmountSongAction = createAction<void, Action.UNMOUNT_SONG>(
  Action.UNMOUNT_SONG
);

export const changeRepeatMode = createAction<void, Action.CHANGE_REPEAT_MODE>(
  Action.CHANGE_REPEAT_MODE
);

export const toggleShuffle = createAction<void, Action.TOGGLE_SHUFFLE>(
  Action.TOGGLE_SHUFFLE
);

export const updatePlaybackProgress = createAction<
  number,
  Action.UPDATE_PLAYBACK_PROGRESS
>(Action.UPDATE_PLAYBACK_PROGRESS);

export const setVolume = createAction<number, Action.SET_VOLUME>(
  Action.SET_VOLUME
);

export const toggleMute = createAction(Action.TOGGLE_MUTE, constPayload);

export const playAction = createAction<void, Action.PLAY>(Action.PLAY);

export const pauseAction = createAction<void, Action.PAUSE>(Action.PAUSE);

export const setQueueSongIds = createAction<
  PlayerQueueItemInput[],
  Action.SET_QUEUE_SONGS
>(Action.SET_QUEUE_SONGS);

export const moveQueueSong = createAction<
  [number, number],
  Action.MOVE_QUEUE_SONG
>(Action.MOVE_QUEUE_SONG);

export const addSongsToQueueAction = createAction<
  { items: PlayerQueueItemInput[]; playNext: boolean },
  Action.ADD_SONGS_TO_QUEUE
>(Action.ADD_SONGS_TO_QUEUE);

export const copyToQueue = createAction<
  {
    songIds: SongId[];
    playingSongIndex: Option<number>;
    playingFrom: HomePath;
  },
  Action.COPY_TO_QUEUE
>(Action.COPY_TO_QUEUE);

export const selectSongInList = createAppAsyncThunk<void, Song>(
  Action.SELECT_SONG_IN_LIST,
  async (song, { dispatch, getState }) => {
    const {
      router: { location },
      workspace: { selectedWorkspaceId },
    } = getState();

    if (!location) {
      throw new Error('Location is not defined');
    }

    if (!selectedWorkspaceId) {
      throw new Error('Workspace is not selected');
    }

    const searchParams = new URLSearchParams();
    searchParams.append('audio_id', song.audioId);

    dispatch(
      push({
        pathname: mkAllFilesPath(selectedWorkspaceId),
        search: searchParams.toString(),
      })
    );
  }
);

export const forward = createAction<number, Action.FORWARD>(Action.FORWARD);

export const seek = createAction<number, Action.SEEK>(Action.SEEK);
